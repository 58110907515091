import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../services/api";
import { toast } from "react-toastify";

const AddBrand = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [brand, setBrand] = useState({
    brandName: "",
    brandLogo: null,
  });
  const imageInput = useRef(null);
  const [success, setSuccess] = useState(false);
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setBrand((prev) => ({
      ...prev,
      brandName: e.target.value,
    }));
  };

  const handleLogoChange = (e) => {
    const selectedFile = e.target.files[0];
    setBrand((prev) => ({
      ...prev,
      brandLogo: selectedFile,
    }));
  };

  useEffect(() => {
    if (id) {
      api.admin.brand
        .getBrand(id)
        .then((res) => {
          if (res.status === 200) {
            const existingBrand = res.data?.Brand;
            setBrand({
              brandName: existingBrand.brand_name,
              brandLogo: existingBrand.brand_image,
            });
          }
        })
        .catch((err) => console.error(err));
    }
  }, [id]);

  const UploadBox = styled(Box)({
    marginTop: 30,
    height: 200,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "divider",
  });

  const isBrandValid = () => {
    return brand.brandName.trim() !== "" && brand.brandLogo !== null;
  };

  const CreateBrand = (e) => {
    e.preventDefault();
    setSuccess(!success);
    setCreating(true);

    const formData = new FormData();
    formData.append("brand_name", brand.brandName);
    formData.append("brand_image", brand.brandLogo);

    if (!isBrandValid()) {
      setSuccess(success);
      toast.error("Kindly fill the fields");
    } else {
      if (id) {
        setSuccess(!success);
        api.admin.brand
          .updateBrand(id, formData)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              toast.success(res.data.message, { autoClose: 2000 });
              setSuccess(!success);
              navigate(`/brands`);
            }
          })
          .catch((err) => console.error(err))
          .finally(() => setCreating(false));
      } else {
        api.admin.brand
          .createBrand(formData)
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message, { autoClose: 2000 });
              setSuccess(!success);
              navigate(`/brands`);
              setBrand({});
            }
          })
          .catch((err) => console.error(err))
          .finally(() => setCreating(false));
      }
    }
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Brand
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "800px",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ my: 2 }}>
          <TextField
            label="Brand Name"
            variant="outlined"
            size="small"
            fullWidth
            value={brand.brandName}
            onChange={handleChange}
          />
        </Box>
        <input
          type="file"
          hidden
          ref={imageInput}
          onChange={handleLogoChange}
        />
        <UploadBox onClick={() => imageInput.current.click()}>
          {brand.brandLogo ? (
            <img
              src={
                brand.brandLogo instanceof File
                  ? URL.createObjectURL(brand.brandLogo)
                  : brand.brandLogo
                  ? "https://ebn.ng/public/upload/brand/" + brand.brandLogo
                  : "https://default-remote-image-url.com"
              }
              alt={brand.brandName}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
              <Typography>
                Drop your image here or{" "}
                <span style={{ color: "#027edd", cursor: "pointer" }}>
                  browse
                </span>
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                JPG, PNG and GIF images are allowed
              </Typography>
            </Box>
          )}
        </UploadBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
          }}
        >
          <Button
            variant="contained"
            disabled={creating === true}
            onClick={CreateBrand}
            sx={{ borderRadius: "20px" }}
          >
            {creating ? <CircularProgress /> : "Submit"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddBrand;
