export const categoriesColumns = [
  {
    accessorKey: "category_name", //access nested data with dot notation
    header: "Name",
  },
  {
    accessorKey: "category_image",
    header: "Image",
    width: 100,
    Cell: ({ cell }) => {
      const imageUrl = `https://api.ebn.ng/public${cell.getValue()}`; // Construct full image URL
      return (
        <div>
          <img src={imageUrl} alt="category" width={50} />
        </div>
      );
    },
  },
];
