// const baseUrl = "https://ebn.ng";
const baseUrl = "https://api.ebn.ng";

const routes = {
  register: (userType) => `${baseUrl}/${userType}/register`,
  login: (userType) => `${baseUrl}/${userType}/login`,
  "refresh-token": () => `${baseUrl}/vendor/refresh`,
  "change-password": (userType) => `${baseUrl}/${userType}/api/update/password`,
  "contact-us": () => `${baseUrl}/user/contact-us`,
  admin: {
    url: () => `${baseUrl}/admin/api`,
    brand: {
      "create-brand": () => `${routes.admin.url()}/store/brand`,
      "get-brand": (id) => `${routes.admin.url()}/brand/${id}`,
      "get-all-brands": () => `${routes.admin.url()}/all/brand`,
      "update-brand": (id) => `${routes.admin.url()}/update/brand/${id}`,
      "delete-brand": (id) => `${routes.admin.url()}/delete/brand/${id}`,
    },
    category: {
      "create-category": () => `${routes.admin.url()}/store/category`,
      "get-categories": () => `${routes.admin.url()}/all/category`,
      "get-category": (id) => `${routes.admin.url()}/category/${id}`,
      "update-category": (id) => `${routes.admin.url()}/update/category/${id}`,
      "delete-category": (id) => `${routes.admin.url()}/delete/category/${id}`,
    },
    subcategory: {
      "create-subcategory": () => `${routes.admin.url()}/store/subcategory`,
      "get-subcategory": (id) => `${routes.admin.url()}/subcategory/${id}`,
      "get-subcategories": () => `${routes.admin.url()}/all/subcategory`,
      "update-subcategory": (id) =>
        `${routes.admin.url()}/update/subcategory/${id}`,
      "delete-subcategory": (id) =>
        `${routes.admin.url()}/delete/subcategory/${id}`,
    },
    vendor: {
      "get-active-vendors": () => `${routes.admin.url()}/active/vendors`,
      "get-inactive-vendors": () => `${routes.admin.url()}/inactive/vendors`,
      "activate-vendor": (id) => `${routes.admin.url()}/vendor/${id}/activate`,
      "deactivate-vendor": (id) =>
        `${routes.admin.url()}/vendor/${id}/deactivate`,
    },
    product: {
      "create-product": () => `${routes.admin.url()}/create/product`,
      "get-products": () => `${routes.admin.url()}/all/products`,
      "get-product": (id) => `${routes.admin.url()}/product/${id}`,
      "edit-product": (id) => `${routes.admin.url()}/edit/product/${id}`,
      "delete-product": (id) => `${routes.admin.url()}/delete/product/${id}`,
      "activate-product": (id) =>
        `${routes.admin.url()}/product/${id}/activate`,
      "deactivate-product": (id) =>
        `${routes.admin.url()}/product/${id}/deactivate`,
      "get-allApprovedProducts": () =>
        `${routes.admin.url()}/all/approved/products`,
      "get-allUnapprovedProducts": () =>
        `${routes.admin.url()}/all/unapproved/products`,
    },
    profile: {
      "get-details": () => `${routes.admin.url()}/show`,
      "update-profile": () => `${routes.admin.url()}/update/profile`,
    },
    coupon: {
      "create-coupon": () => `${routes.admin.url()}/create/coupon`,
      "delete-coupon": (id) => `${routes.admin.url()}/delete/coupon/${id}`,
      "get-all-coupons": () => `${routes.admin.url()}/get/all/coupon`,
      "update-coupon": (id) => `${routes.admin.url()}/edit/coupon/${id}`,
    },
    sliders: {
      "create-slider": () => `${routes.admin.url()}/create/slider`,
      "delete-slider": (id) => `${routes.admin.url()}/delete/slider/${id}`,
      "get-all-sliders": () => `${routes.admin.url()}/get/all/slider`,
      "update-slider": (id) => `${routes.admin.url()}/edit/slider/${id}`,
    },
    claims: {
      "view-claim": (id) => `${routes.admin.url()}/view/claim/${id}`,
      "open-claim": (id) => `${routes.admin.url()}/open/report/${id}`,
      "close-claim": (id) => `${routes.admin.url()}/close/report/${id}`,
    },
    transactions: {
      "get-all-transactions": () =>
        `${routes.admin.url()}/get/all/transactions`,
    },
    bidding: {
      "get-all-auctions": () => `${routes.admin.url()}/get/all/auctions`,
      "get-all-bidding-history": () => `${routes.admin.url()}/get/all/biddings`,
    },
  },
  vendor: {
    url: () => `${baseUrl}/vendor/api`,
    // "reset-password": () => `${routes.customer.url()}/resetPassword`,
    // "activate-resetPassword": () =>
    //  `${routes.customer.url()}/activateResetPassword`,
    brand: {
      "create-brand": () => `${routes.vendor.url()}/store/brand`,
      "get-brand": (id) => `${routes.vendor.url()}/brand/${id}`,
      "get-all-brands": () => `${routes.vendor.url()}/all/brand`,
      "update-brand": (id) => `${routes.vendor.url()}/update/brand/${id}`,
      "delete-brand": (id) => `${routes.vendor.url()}/delete/brand/${id}`,
    },
    category: {
      "create-category": () => `${routes.vendor.url()}/store/category`,
      "get-categories": () => `${routes.vendor.url()}/all/category`,
      "get-category": (id) => `${routes.vendor.url()}/category/${id}`,
      "update-category": (id) => `${routes.vendor.url()}/update/category/${id}`,
      "delete-category": (id) => `${routes.vendor.url()}/delete/category/${id}`,
    },
    subcategory: {
      "create-subcategory": () => `${routes.vendor.url()}/store/subcategory`,
      "get-subcategory": (id) => `${routes.vendor.url()}/subcategory/${id}`,
      "get-subcategories": (id) => `${routes.vendor.url()}/all/subcategory/${id}`,
      "update-subcategory": (id) =>
        `${routes.vendor.url()}/update/subcategory/${id}`,
      "delete-subcategory": (id) =>
        `${routes.vendor.url()}/delete/subcategory/${id}`,
    },
    product: {
      "create-product": () => `${routes.vendor.url()}/create/product`,
      "get-products": () => `${routes.vendor.url()}/all/products`,
      "edit-product": (id) => `${routes.vendor.url()}/edit/product/${id}`,
      "delete-product": (id) => `${routes.vendor.url()}/delete/product/${id}`,
      "get-product": (id) => `${routes.vendor.url()}/product/${id}`,
      "get-allApprovedProducts": () =>
        `${routes.vendor.url()}/all/approved/products`,
      "get-allUnapprovedProducts": () =>
        `${routes.vendor.url()}/all/unapproved/products`,
      'edit-product-thumbnail': (id) => `${routes.vendor.url()}/edit/product/thumbnail/${id}`,
      'edit-product-multi-imgs': (id) => `${routes.vendor.url()}/edit/product/multi/img/${id}`
    },
    profile: {
      "get-details": () => `${routes.vendor.url()}/show`,
      "update-profile": () => `${routes.vendor.url()}/update/profile`,
    },
    auction: {
      "create-auction": () => `${routes.vendor.url()}/create/auction`,
      "get-all-auctions": () => `${routes.vendor.url()}/get/all/auctions`,
    },
    orders:{
      'get-all-orders': () => `${routes.vendor.url()}/get/orders`,
      'get-total-order': () => `${routes.vendor.url()}/get/total/order`,
      'get-total-sales': () => `${routes.vendor.url()}/get/total/sales`,
      'confirm-order-status': () => `${routes.vendor.url()}/confirm/order/status`,
    }
  },
};

export default routes;
