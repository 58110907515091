import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Table from "../components/Table";
import { couponsColumns } from "../data/coupons";
import api from "../services/api";
import Danger from "../assets/danger-sign.png";
import Modal from "../components/Modal";
import { toast } from "react-toastify";
import styled from "@emotion/styled";
import { BiImageAdd } from "react-icons/bi";

const Sliders = () => {
  const [showForm, setShowForm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [sliders, setSliders] = useState([]);
  const [slider, setSlider] = useState({
    slider_title: "",
    short_title: "",
    slider_image: null,
  });
  const imageInput = useRef(null);

  const handleLogoChange = (e) => {
    const selectedFile = e.target.files[0];
    setSlider((prev) => ({
      ...prev,
      slider_image: selectedFile,
    }));
  };
  const fetchSliders = () => {
    setLoading(true);
    api.admin.sliders
      .getAllSliders()
      .then((res) => {
        console.log(res);
      })
      .catch((res) => console.log(res))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchSliders();
  }, []);

  const showCouponForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSlider({
      ...slider,
      [name]: value,
    });
  };

  const createSlider = () => {
    setIsCreating(true);
    const formData = new FormData();
    formData.append("slider_title", slider.slider_title);
    formData.append("short_title", slider.short_title);
    formData.append("slider_image", slider.slider_image);

    if (isUpdating) {
      api.admin.sliders
        .updateSlider(deleteId, formData)
        .then((res) => {
          fetchSliders();
          toast.success(res.data.message);
          setSlider({
            slider_title: "",
            short_title: "",
            slider_image: null,
          });
          setIsUpdating(false);
          setShowForm(false);
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    } else {
      api.admin.sliders
        .createSlider(formData)
        .then((res) => {
          fetchSliders();
          toast.success(res.data.message);
          setSlider({
            slider_title: "",
            short_title: "",
            slider_image: null,
          });
          setShowForm(false);
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    }
  };

  const deleteCoupon = (id) => {
    setDeleting(true);
    api.admin.coupon
      .deleteCoupon(id)
      .then((res) => {
        toast.success("Coupon deleted successfully");
        setSliders((preSliders) =>
          preSliders.filter((slider) => slider.id !== id)
        );
        setIsModalOpen(false);
      })
      .catch((res) => console.log(res))
      .finally(() => setDeleting(false));
  };

  const editCoupon = (coupon) => {
    setSlider({
      slider_title: slider.slider_title,
      short_title: slider.short_title,
      slider_image: slider.slider_image,
    });
    setDeleteId(coupon.id);
    setIsUpdating(true);
    setShowForm(true);
  };

  const openModal = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  const UploadBox = styled(Box)({
    marginTop: 30,
    height: 200,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "divider",
  });

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Sliders</Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          onClick={showCouponForm}
        >
          Add Slider
        </Button>
      </Box>
      {showForm && (
        <Box sx={{ py: 2, px: 3, my: 4, backgroundColor: "white" }}>
          <form>
            <Paper
              sx={{
                boxShadow: "none !important",
                borderRadius: "12px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "divider",
                p: "20px",
                maxWidth: "800px",
                margin: "0 auto",
                cursor: "pointer",
                overflow: "hidden",
              }}
            >
              <Box sx={{ my: 2 }}>
                <TextField
                  label="Slider Title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={slider.slider_title}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ my: 2 }}>
                <TextField
                  label="Short Title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={slider.short_title}
                  onChange={handleChange}
                />
              </Box>
              <input
                type="file"
                hidden
                ref={imageInput}
                onChange={handleLogoChange}
              />
              <UploadBox onClick={() => imageInput.current.click()}>
                {slider.slider_image ? (
                  <img
                    src={
                      slider.slider_image instanceof File
                        ? URL.createObjectURL(slider.slider_image)
                        : slider.slider_image
                        ? "https://ebn.ng/public/upload/brand/" +
                          slider.slider_image
                        : "https://default-remote-image-url.com"
                    }
                    alt={slider.slider_title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <Box sx={{ textAlign: "center" }}>
                    <BiImageAdd
                      style={{ fontSize: "50px", color: "#027edd" }}
                    />
                    <Typography>
                      Drop your image here or{" "}
                      <span style={{ color: "#027edd", cursor: "pointer" }}>
                        browse
                      </span>
                    </Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      JPG, PNG and GIF images are allowed
                    </Typography>
                  </Box>
                )}
              </UploadBox>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "30px",
                }}
              >
                <Button
                  variant="contained"
                  disabled={isCreating === true}
                  onClick={createSlider}
                  sx={{ borderRadius: "20px" }}
                >
                  {isCreating ? <CircularProgress /> : "Submit"}
                </Button>
              </Box>
            </Paper>
          </form>
        </Box>
      )}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={sliders}
          fields={couponsColumns}
          numberOfRows={sliders.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
          couponEdit={editCoupon}
          handleDelete={openModal}
        />
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={Danger} alt="Danger" style={{ width: "100px" }} />
          <Typography variant="h6">Are you sure you want to delete?</Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              disabled={deleting === true}
              onClick={() => deleteCoupon(deleteId)}
            >
              {deleting ? <CircularProgress /> : "Yes"}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              disabled={deleting === true}
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Sliders;
