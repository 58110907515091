import { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const storedUser = JSON.parse(sessionStorage.getItem("user")) || {};
  const [user, setUser] = useState(storedUser);

  useEffect(() => {
    sessionStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const logout = () => {
    window.location.replace("/");
    setUser({});
    localStorage.clear();
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
