import styled from "@emotion/styled";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { lineChartData, lineChartOptions } from "../../../data/chartData";
// import { stats } from "../../../data/stats";
import LineChart from "../charts/LineChart";
import { FiShoppingBag, FiUsers } from "react-icons/fi";
import { BsCurrencyDollar } from "react-icons/bs";
import api from "../../../services/api";
import { useUser } from "../../../Context/UserProvider";

const Stats = () => {
  const { user } = useUser();
  const role = user?.role;
  const Item = styled(Paper)({
    padding: "5px 10px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  });

  const [stats, setStats] = useState([
    {
      id: 1,
      title: "Total Orders",
      amount: 0, // Placeholder value
      icon: <FiShoppingBag />,
      iconColor: "#6427ff",
      iconBg: "rgba(100, 39, 255, 0.2)",
      isMoney: false,
    },
    {
      id: 2,
      title: "Total Sales",
      amount: 0, // Placeholder value
      icon: <BsCurrencyDollar />,
      iconColor: "#c49c1a",
      iconBg: "rgba(196, 156, 26, 0.2)",
      isMoney: true,
    },
    {
      id: 3,
      title: "Total Customers",
      amount: 0, // Placeholder value
      icon: <FiUsers />,
      iconColor: "#fd4332",
      iconBg: "rgba(253, 67, 50, 0.2)",
      isMoney: false,
    },
  ]);

  const getStats = async () => {
    try {
      // Simulate API calls (replace with your actual API functions)
      const totalSales = await api.vendor.orders.getTotalSales();
      const totalOrders = await api.vendor.orders.getTotalOrders();

      setStats((prevStats) =>
        prevStats.map((stat) => {
          if (stat.title === "Total Orders") {
            return { ...stat, amount: totalOrders.data?.totalOrders };
          }
          if (stat.title === "Total Sales") {
            return { ...stat, amount: totalSales?.data?.total_sales };
          }
          // if (stat.title === "Total Customers") {
          //   return { ...stat, amount: totalCustomers };
          // }
          return stat;
        })
      );
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  useEffect(() => {
    if (role === "vendor") {
      getStats();
    } else {
      // setStats(stats)
    }
  }, [role]);

  return (
    <Grid container spacing={2}>
      {stats.map(
        ({ id, title, amount, icon, iconBg, iconColor, isMoney }, i) => (
          <Grid
            item
            xs={12}
            sm={i === stats?.length - 1 ? 12 : 6}
            lg={4}
            key={id}
          >
            <Item
              sx={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "divider",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <IconButton
                  sx={{ background: `${iconBg} !important`, color: iconColor }}
                >
                  {icon}
                </IconButton>
                <Typography variant="h4" sx={{ my: 2 }}>
                  {`${isMoney ? " ₦" + amount : amount}`}
                </Typography>
                <Typography sx={{ opacity: 0.7 }}>{title}</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <LineChart
                  chartOptions={lineChartOptions}
                  chartData={lineChartData}
                />
              </Box>
            </Item>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default Stats;
