export const couponsColumns = [
  {
    accessorKey: "coupon_name", //access nested data with dot notation
    header: "Name",
  },
  {
    accessorKey: "coupon_discount", //access nested data with dot notation
    header: "Discount",
    Cell: ({ cell }) => <span>{cell.getValue()}%</span>,
  },
  {
    accessorKey: "coupon_validity", //access nested data with dot notation
    header: "Validity",
  },
];
