/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Button, IconButton, Tooltip } from "@mui/material";
import {
  FiCheckCircle,
  FiEye,
  FiPenTool,
  FiPlayCircle,
  FiTrash,
} from "react-icons/fi";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useUser } from "../Context/UserProvider";

export const Table = ({
  data,
  fields,
  numberOfRows,
  enableTopToolBar,
  enableBottomToolBar,
  enablePagination,
  enableColumnFilters,
  enableEditing,
  enableColumnDragging,
  showPreview,
  routeLink,
  products,
  vendor,
  handleActivateProduct,
  handleDelete,
  handleVendor,
  editing,
  handleEditing,
  couponEdit,
  auction,
}) => {
  const columns = useMemo(() => fields, []);

  const [tableData, setTableData] = useState(() => data);

  const { user } = useUser();
  const role = user?.role;

  useEffect(() => {
    if (data.length) {
      setTableData(data);
    }
  }, [data]);

  return (
    <MaterialReactTable
      columns={columns}
      data={tableData?.slice(0, numberOfRows)}
      getRowId={(row) => row.id}
      enableEditing={enableEditing}
      enableColumnDragging={enableColumnDragging}
      enableColumnOrdering
      enableColumnFilters={enableColumnFilters}
      enablePagination={enablePagination}
      enableBottomToolbar={enableBottomToolBar}
      enableTopToolbar={enableTopToolBar}
      renderRowActions={({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {handleDelete && (
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDelete(row.id)}>
                <FiTrash />
              </IconButton>
            </Tooltip>
          )}
          {editing && (
            <Tooltip arrow placement="right" title="Edit">
              <IconButton color="success" onClick={() => handleEditing(row.id)}>
                <FiPenTool />
              </IconButton>
            </Tooltip>
          )}
          {couponEdit && role === "admin" && (
            <Tooltip arrow placement="right" title="Edit">
              <IconButton
                color="success"
                onClick={() => couponEdit(row.original)}
              >
                <FiPenTool />
              </IconButton>
            </Tooltip>
          )}
          {products && role === "vendor" && (
            <>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "green",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
                onClick={() => auction(row.id)}
              >
                Auction
              </Button>
              <Tooltip arrow placement="right" title="Edit">
                <IconButton
                  color="success"
                  onClick={() => handleEditing(row.id)}
                >
                  <FiPenTool />
                </IconButton>
              </Tooltip>
            </>
          )}
          {products && role === "admin" && (
            <Box>
              {row.original.status === "active" ? (
                <Tooltip arrow placement="right" title="Activated">
                  <IconButton color="disabled">
                    <FiCheckCircle />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip arrow placement="right" title="Activate">
                  <IconButton
                    color="success"
                    onClick={() => handleActivateProduct(row.id)}
                  >
                    <FiPlayCircle />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
          {vendor && (
            <Box>
              {row.original.status === "active" ? (
                <Tooltip arrow placement="right" title="Deactivate">
                  <IconButton
                    color="error"
                    onClick={() => handleVendor(row.original)}
                  >
                    <FiCheckCircle />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip arrow placement="right" title="Activate">
                  <IconButton
                    color="success"
                    onClick={() => handleVendor(row.original)}
                  >
                    <FiPlayCircle />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
          {showPreview && routeLink && (
            <Tooltip arrow placement="right" title="View">
              <Link to={`/${routeLink}/${row.id}`}>
                <IconButton>
                  <FiEye />
                </IconButton>
              </Link>
            </Tooltip>
          )}
        </Box>
      )}
      muiTableBodyRowProps={{ hover: false }}
      muiTablePaperProps={{
        sx: {
          padding: "20px",
          borderRadius: "15px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
        },
      }}
      muiTableContainerProps={{
        sx: { borderRadius: "15px" },
      }}
      muiTableHeadCellProps={{
        sx: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      }}
      muiTableHeadProps={{
        sx: {
          "& tr th": {
            borderWidth: "1px",
            borderColor: "divider",
            borderStyle: "solid",
          },
        },
      }}
      muiTableBodyProps={{
        sx: {
          "& tr td": {
            borderWidth: "1px",
            borderColor: "divider",
            borderStyle: "solid",
          },
        },
      }}
    />
  );
};

export default Table;
