export const suppliersColumns = [
  {
    accessorKey: "photo", //access nested data with dot notation
    header: "Logo",
    width: 100,
    Cell: ({ cell }) => {
      const imageUrl = `https://ebn.itl.ng/${cell.getValue()}`; // Construct full image URL
      return (
        <div>
          <img src={imageUrl} alt="vendor" width={50} />
        </div>
      );
    },
  },
  {
    accessorKey: "name", //access nested data with dot notation
    header: "Vendor's Name",
  },
  {
    accessorKey: "username", //access nested data with dot notation
    header: "Username",
  },
  {
    accessorKey: "phone",
    header: "Phone",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
];
