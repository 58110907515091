import { Avatar, Divider, TextField, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useUser } from "../../Context/UserProvider";
import api from "../../services/api";
import { toast } from "react-toastify";

const Profile = () => {
  const { user } = useUser();

  // Local state to manage form input values
  const [profileData, setProfileData] = useState({
    name: user?.name || "",
    username: user?.username || "",
    email: user?.email || "",
    phone: user?.phone || "",
    address: user?.address || "",
    photo: user?.photo || null,
  });

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setProfileData((prev) => ({ ...prev, photo: selectedFile }));
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  // Handle form submission to update the profile
  const handleUpdate = (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Append form fields to FormData
    formData.append("name", profileData.name);
    formData.append("phone", profileData.phone);
    formData.append("address", profileData.address);

    // Only append the photo if it's a file (i.e., the user selected a new one)
    if (profileData.photo instanceof File) {
      formData.append("photo", profileData.photo);
    }

    formData.append("username", profileData.username);

    // Call API to update the profile
    api.vendor.profile
      .updateProfile(formData)
      .then((res) => {
        toast.success("Profile Updated Successfully");
      })
      .catch((error) => {
        toast.error("Failed to update profile. Please try again.");
      });
  };

  return (
    <Box>
      <Typography variant="subtitle1">Profile</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.8, pb: 2 }}>
        Update your photo and profile here
      </Typography>
      <Divider />
      <Box sx={{ mt: 3 }}>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">Profile image</Typography>
          <Avatar
            src={
              profileData?.photo instanceof File
                ? URL.createObjectURL(profileData?.photo)
                : profileData?.photo
                ? `https://ebn.itl.ng/public/upload/admin_images${profileData?.photo}`
                : "/images/avatars/profile-avatar.png"
            }
            sx={{ width: 80, height: 80 }}
          />
        </Box>

        {/* Input to upload a new avatar */}
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" component="label">
            Upload New Avatar
            <input type="file" hidden onChange={handlePhotoChange} />
          </Button>
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="First Name"
            name="name"
            variant="outlined"
            fullWidth
            size="small"
            value={profileData.name}
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Username"
            name="username"
            variant="outlined"
            size="small"
            fullWidth
            value={profileData.username}
            disabled
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Email"
            name="email"
            variant="outlined"
            size="small"
            fullWidth
            value={profileData.email}
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Address"
            name="address"
            variant="outlined"
            size="small"
            fullWidth
            value={profileData.address}
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Phone"
            name="phone"
            variant="outlined"
            size="small"
            fullWidth
            value={profileData.phone}
            onChange={handleInputChange}
          />
        </Box>

        {/* Update Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdate}
          sx={{ mt: 2 }}
        >
          Update Profile
        </Button>
      </Box>
    </Box>
  );
};

export default Profile;
