export const subcategoriesColumns = [
  {
    accessorKey: "category_id", //access nested data with dot notation
    header: "Category Id",
  },
  {
    accessorKey: "subcategory_name", //access nested data with dot notation
    header: "Name",
  }
];
