import { BsCurrencyDollar } from "react-icons/bs";
import { FaHandshake, FaShare } from "react-icons/fa";
import {
  FiHome,
  FiLayers,
  FiMessageCircle,
  FiSettings,
  FiShoppingBag,
  FiShoppingCart,
  FiUsers,
} from "react-icons/fi";

export const links = [
  {
    name: "Dashboard",
    icon: <FiHome />,
    url: "/dashboard",
    roles: ["admin", "vendor"],
  },
  {
    name: "Products",
    icon: <FiShoppingBag />,
    subLinks: [
      {
        name: "All Products",
        url: "/products",
        roles: ["admin", "vendor"],
      },
      {
        name: "Add Product",
        url: "/products/add",
        roles: ["vendor"],
      },
      // {
      //   name: "Product Category",
      //   url: "/products/categories",
      // },
    ],
    roles: ["admin", "vendor"],    
  },
  {
    name: "Brands",
    icon: <FiLayers />,
    subLinks: [
      {
        name: "All brands",
        url: "/brands",
        roles: ["admin"],
      },
      {
        name: "Add brand",
        url: "/brands/add",
        roles: ["admin"],
      },
    ],
    roles: ["admin"],
  },
  {
    name: "Categories",
    icon: <FiLayers />,
    subLinks: [
      {
        name: "All categories",
        url: "/products/categories",
        roles: ["admin"],
      },
      {
        name: "Add category",
        url: "/products/categories/add",
        roles: ["admin"],
      },
    ],
    roles: ["admin"],
  },
  {
    name: "Subcategories",
    icon: <FiLayers />,
    subLinks: [
      {
        name: "All subcategories",
        url: "/products/subcategories",
        roles: ["admin"],
      },
      {
        name: "Add Subcategory",
        url: "/products/subcategories/add",
        roles: ["admin"],
      },
    ],
    roles: ["admin"],
  },
  {
    name: "Coupons",
    icon: <FiLayers />,
    url: '/coupons',
    roles: ["admin"],
  },
  {
    name: "Auctions",
    icon: <FaHandshake />,
    url: "/auctions",
    roles: ["admin", "vendor"],
  },
  {
    name: "Vendors",
    icon: <FaShare />,
    url: "/vendors",
    roles: ["admin"],
  },
  {
    name: "Customers",
    icon: <FiUsers />,
    url: "/customers",
    roles: ["admin"],
  },
  {
    name: "Sliders",
    icon: <FiUsers />,
    url: "/sliders",
    roles: ["admin"],
  },
  {
    name: "Sales",
    icon: <BsCurrencyDollar />,
    subLinks: [
      {
        name: "Sales Analytics",
        url: "/sales/analysis",
      },
      {
        name: "Product Sales",
        url: "/sales",
      },
    ],
    roles: ["admin", "vendor"],
  },
  {
    name: "Orders",
    icon: <FiShoppingCart />,
    url: "/orders",
    roles: ["admin", "vendor"],
  },
  {
    name: "Transactions",
    icon: <FaHandshake />,
    url: "/transactions",
    roles: ["admin", "vendor"],
  },
  
  {
    name: "Reviews",
    icon: <FiMessageCircle />,
    url: "/reviews",
    roles: ["admin"],
  },
  {
    name: "Settings",
    icon: <FiSettings />,
    url: "/settings",
    roles: ["admin", "vendor"],
  },
  // {
  //   name: "Inbox",
  //   icon: <FiMail />,
  //   url: "/inbox",
  // },
];
