export const orderDetailsColumns = [
  {
    accessorKey: "product_name",
    header: "Product Name",
  },
  {
    accessorKey: "price",
    header: "Price",
    Cell: ({ cell }) => <span>₦{cell.getValue()}</span>,
  },
  {
    accessorKey: "qty",
    header: "Quantity",
  },
  {
    accessorKey: "shipping_option",
    header: "Shipping Option",
  },
  {
    accessorKey: "size",
    header: "Size",
  },
  {
    accessorKey: "color",
    header: "Color",
  },
  {
    header: "Total",
    Cell: ({ row }) => {
      const price = row.original.price;
      const qty = row.original.qty; 
      const total = price * qty;
      return <span>₦{total}</span>;
    },
  },
];
