import styled from "@emotion/styled";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import api from "../services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../Context/UserProvider";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";

const AddProduct = () => {
  const { user } = useUser();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const editorRef = useRef(null);

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    product_name: "",
    product_tags: [],
    product_size: [],
    product_color: [],
    short_description: "",
    long_description: "",
    product_thumbnail: null,
    selling_price: 0,
    discount_price: null,
    product_code: "",
    product_qty: 0,
    brand_id: "",
    category_id: "",
    sub_category_id: "",
    vendor_id: "",
    hot_deals: 0,
    special_offer: 0,
    special_deals: 0,
    featured: 0,
    created_at: "",
    sku: "",
    multi_img: [],
  });

  const dealTypes = ["hot_deals", "special_offer", "special_deals", "featured"];

  const imageInput = useRef(null);
  const multiImageInputs = useRef([]);
  const [tagInput, setTagInput] = useState("");
  const [sizeInput, setSizeInput] = useState("");
  const [colorInput, setColorInput] = useState("");

  const [allCategories, setAllCategories] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [searchBrand, setSearchBrand] = useState("");
  const [subCategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCat, setSelectedCat] = useState(null);

  const [updatedThumbnail, setUpdatedThumbnail] = useState(false);
  const [updatedMulti, setUpdatedMulti] = useState(false);
  const [updateThumbnail, setUpdateThumbnail] = useState({
    old_img: null,
    product_thumbnail: null,
  });
  const [updateMulti, setUpdateMulti] = useState({
    old_img: [],
    multi_img: [],
  });

  useEffect(() => {
    if (id) {
      api.vendor.products
        .getProduct(id)
        .then((res) => {
          const apiResponse = res.data.Product;
          setUpdateMulti((prev) => ({
            ...prev,
            multi_img: res?.data?.thumbnail,
          }));
          setUpdateThumbnail((prev) => ({
            ...prev,
            product_thumbnail: apiResponse.product_thumbnail,
          }));
          setProduct((prevProduct) => ({
            ...prevProduct,
            product_name: apiResponse.product_name,
            product_tags: JSON.parse(apiResponse.product_tags),
            product_size: JSON.parse(apiResponse.product_size),
            product_color: JSON.parse(apiResponse.product_color),
            short_description: apiResponse.short_description,
            long_description: apiResponse.long_description,
            product_thumbnail: apiResponse.product_thumbnail,
            selling_price: parseFloat(apiResponse.selling_price), // Convert to float
            discount_price:
              apiResponse.discount_price !== "null"
                ? parseFloat(apiResponse.discount_price)
                : null,
            product_code: apiResponse.product_code,
            product_qty: parseInt(apiResponse.product_qty), // Convert to integer
            brand_id: apiResponse.brand_id,
            category_id: apiResponse.category_id,
            sub_category_id: apiResponse.subcategory_id,
            vendor_id: apiResponse.vendor_id,
            hot_deals: apiResponse.hot_deals,
            featured: apiResponse.featured,
            special_offer: apiResponse.special_offer,
            special_deals: apiResponse.special_deals,
            created_at: apiResponse.created_at,
            sku: "",
          }));
          setProduct((prev) => ({
            ...prev,
            multi_img: res?.data?.thumbnail,
          }));
          setLoading(false);
        })
        .catch(console.error);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    api.vendor.category
      .getCategories()
      .then((res) => {
        setAllCategories(res.data?.categories);
      })
      .catch((res) => console.log(res))
      .finally(() => setLoading(false));
    api.vendor.brand
      .getBrands()
      .then((res) => {
        setAllBrands(res.data?.brands);
      })
      .catch((res) => console.log(res))
      .finally(() => setLoading(false));

  }, []);

  useEffect(() => {
    api.vendor.subcategory
      .getSubcategories(selectedCat)
      .then((res) => {
        setSubcategories(res.data);
      })
      .catch((res) => { });
  }, [selectedCat])

  // product code generation
  useEffect(() => {
    if (!id) {
      const generateCode = () => {
        const uniqueProductCode = `PROD-${Date.now()
          .toString()
          .slice(-3)}-${uuidv4().slice(0, 11)}`;
        setProduct((prevProduct) => ({
          ...prevProduct,
          product_code: uniqueProductCode,
        }));
      };
      const generateSku = () => {
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}${month}${day}`;
        };

        // Function to format time as HH:MM:SS
        const formatTime = (date) => {
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const seconds = String(date.getSeconds()).padStart(2, "0");
          return `${hours}${minutes}${seconds}`;
        };

        // Generate the date part of the SKU
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        const formattedTime = formatTime(currentDate);

        // Combine user's name, date, and time
        const userNameWithoutSpaces = user.name
          .replace(/\s/g, "")
          .substring(0, 8);
        const dateTimeString = `${userNameWithoutSpaces}${formattedDate}${formattedTime}`;

        // Truncate or pad to desired length
        const desiredLength = 40; // Change this to your desired length
        const product_sku = dateTimeString
          .substring(0, desiredLength)
          .padEnd(desiredLength, "0");
        setProduct((prevProduct) => ({
          ...prevProduct,
          sku: product_sku,
        }));
      };
      generateCode();
      generateSku();
    }
  }, [id, user.name]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (
      name === "discount_price" &&
      parseFloat(value) > product.selling_price
    ) {
      alert("Discount price must be less than selling price");
    }

    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
    if (name === "category_id") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        sub_category_id: "",
      }));
      setSelectedCat(value)
    }
  };

  const handleEditorChange = (content) => {
    setProduct((prev) => ({
      ...prev,
      long_description: content,
    }));
  };
  const handleEditorShortChange = (content) => {
    setProduct((prev) => ({
      ...prev,
      short_description: content,
    }));
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (id) {
      setUpdateThumbnail((prev) => ({
        ...prev,
        product_thumbnail: file,
      }));
    }
    setProduct((prev) => ({
      ...prev,
      product_thumbnail: file,
    }));
  };

  const handleMultiImageChange = (index, e) => {
    const file = e.target.files[0];
    const newMultiImages = [...product.multi_img];

    if (id) {
      setUpdateMulti((prev) => ({
        ...prev,
        multi_img: newMultiImages,
      }));
    }

    // Update or add the image to the array
    if (file) {
      newMultiImages[index] = file;
    }

    setProduct((prev) => ({
      ...prev,
      multi_img: newMultiImages,
    }));
  };
  const addPlaceholderImages = () => {
    const imgArray = product.multi_img || []; // Ensure multi_img is always an array
    const imagesToShow = imgArray.length > 0 ? imgArray : []; // Handle empty array case for new product creation
    const placeholdersCount = Math.max(0, 4 - imagesToShow.length); // Ensure valid placeholder count
    const placeholders = Array(placeholdersCount).fill(null); // Add placeholders
    return [...imagesToShow, ...placeholders]; // Merge actual images with placeholders
  };

  const handleChipsInputChange = (setInput) => (event) => {
    setInput(event.target.value);
  };

  // Handle adding items (tags, sizes, colors)
  const handleAddItem = (inputValue, setInput, key) => (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [key]: [...prevProduct[key], inputValue.trim()],
      }));
      setInput(""); // Clear the input after adding
      event.preventDefault(); // Prevent form submission
    }
  };

  // Handle deleting items (tags, sizes, colors)
  const handleDeleteItem = (itemToDelete, key) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      [key]: prevProduct[key].filter((item) => item !== itemToDelete),
    }));
  };

  const handleDealTypeChange = (event) => {
    const selectedDeal = event.target.value;

    // Set the selected deal type to 1 and the others to 0
    setProduct((prevProduct) => {
      const updatedProduct = dealTypes.reduce((acc, dealType) => {
        acc[dealType] = dealType === selectedDeal ? 1 : 0; // Set the selected deal to 1 and others to 0
        return acc;
      }, {});
      return { ...prevProduct, ...updatedProduct };
    });
  };

  const UploadBox = styled(Box)({
    marginTop: 30,
    height: 200,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "divider",
  });

  const isProductValid = () => {
    const excludedFields = [
      "vendor_id",
      "hot_deals",
      "special_offer",
      "special_deals",
      "featured",
      "created_at",
      "sku",
      "discount_price",
    ];
    const emptyFields = [];

    for (const key in product) {
      if (product.hasOwnProperty(key) && !excludedFields.includes(key)) {
        const value = product[key];

        // Exclude 0 from the empty check
        if (
          value === null ||
          value === "" ||
          (typeof value === "number" && isNaN(value))
        ) {
          emptyFields.push(key);
        }
      }
    }

    return emptyFields;
  };

  const validateDescriptions = () => {
    const shortWords = product.short_description.trim().split(/\s+/).length;
    const longWords = product.long_description.trim().split(/\s+/).length;

    if (shortWords < 20) {
      return "Short description must not be less than 20 words.";
    }

    if (longWords < 50) {
      return "Long description must not be less than 50 words.";
    }

    return null;
  };

  const updateThumbnailImage = () => {
    const formData = new FormData();
    formData.append("old_img", updateThumbnail.old_img);
    formData.append("product_thumbnail", updateThumbnail.product_thumbnail);
    api.vendor.products
      .editProductThumbnail(id, formData)
      .then((res) => {
        setUpdatedThumbnail(true);
      })
      .catch(console.error);
  };
  const updateMultiImages = () => {
    const formData = new FormData();
    updateMulti.old_img.forEach((image, index) => {
      formData.append(`old_img[${index}]`, image);
    });
    updateMulti.multi_img.forEach((image, index) => {
      formData.append(`multi_img[${index}]`, image);
    });
    api.vendor.products
      .editProductImages(id, formData)
      .then((res) => {
        console.log(res);
        setUpdatedMulti(true);
      })
      .catch(console.error);
  };

  const CreateProduct = (e) => {
    e.preventDefault();
    const emptyFields = isProductValid();
    if (emptyFields.length > 0) {
      toast.error(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      return;
    }
    if (Number(product.discount_price) > product.selling_price) {
      alert("Discount price must be less than selling price");
      return;
    }
    const validationError = validateDescriptions();
    if (validationError) {
      toast.error(validationError);
      return;
    }
    setSuccess(!success);

    const currentTime = new Date().toLocaleString();

    const formData = new FormData();
    formData.append("brand_id", product.brand_id);
    formData.append("category_id", product.category_id);
    formData.append("subcategory_id", product.sub_category_id);
    formData.append("product_name", product.product_name);
    formData.append("product_thumbnail", product.product_thumbnail);
    formData.append("product_code", product.product_code);
    formData.append("product_qty", product.product_qty);

    formData.append("selling_price", product.selling_price);
    formData.append("discount_price", product.discount_price);
    formData.append("short_description", product.short_description);
    formData.append("long_description", product.long_description);

    formData.append("hot_deals", product.hot_deals);
    formData.append("special_offer", product.special_offer);
    formData.append("special_deals", product.special_deals);
    formData.append("featured", product.featured);

    formData.append("created_at", currentTime);
    formData.append("sku", product.sku);

    formData.append("product_tags", JSON.stringify(product.product_tags));
    formData.append("product_size", JSON.stringify(product.product_size));
    formData.append("product_color", JSON.stringify(product.product_color));

    product.multi_img.forEach((image, index) => {
      formData.append(`multi_img[${index}]`, image);
    });

    if (id) {
      api.vendor.products
        .editProduct(id, formData)
        .then((res) => {
          toast.success(res.data?.message);
          navigate(`/products`);
        })
        .catch((res) => console.log(res))
        .finally(() => setSuccess(!success));
    } else {
      api.vendor.products
        .createProduct(formData)
        .then((res) => {
          toast.success(res.data?.message);
          navigate(`/products`);
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => setSuccess(!success));
    }
  };

  const filteredBrands = allBrands?.filter((brand) =>
    brand.brand_name.toLowerCase().includes(searchBrand.toLowerCase())
  );

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Product
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "800px",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ my: 2 }}>
          <TextField
            label="Product Name"
            variant="outlined"
            size="small"
            fullWidth
            name="product_name"
            value={product.product_name}
            onChange={handleInputChange}
          />
        </Box>
        {/* product description */}
        <Box
          sx={{ mt: 4, display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Editor
            value={product.short_description}
            onEditorChange={handleEditorShortChange}
            onInit={(_evt, editor) => (editorRef.current = editor)}
            apiKey={"qnjby2dxdumnsbuveq8h82nqcoss841ppwgnep0bhsf32sj0"}
            init={{
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              ai_request: (request, respondWith) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
              forced_root_block: false,
            }}
            initialValue="Short Description here"
          />
          <Editor
            value={product.long_description}
            onEditorChange={handleEditorChange}
            onInit={(_evt, editor) => (editorRef.current = editor)}
            apiKey={"qnjby2dxdumnsbuveq8h82nqcoss841ppwgnep0bhsf32sj0"}
            init={{
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
              toolbar:
                "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              ai_request: (request, respondWith) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
              forced_root_block: false,
            }}
            initialValue="Long Description here"
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Category"
              name="category_id"
              value={product.category_id}
              onChange={handleInputChange}
            >
              {loading ? (
                <MenuItem value={""}>Select Category here</MenuItem>
              ) : (
                allCategories?.map((category) => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.category_name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 4 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Subcategory</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Subcategory"
              name="sub_category_id"
              value={product.sub_category_id}
              onChange={handleInputChange}
              disabled={product.category_id === ""}
            >
              {subCategories?.length > 0 ? (
                subCategories.map((sub) => (
                  <MenuItem value={sub.id} key={sub.id}>
                    {sub.subcategory_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="">No subcategories available</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 4 }}>
          <TextField
            fullWidth
            size="small"
            label="Search Brand"
            variant="outlined"
            value={searchBrand}
            onChange={(e) => setSearchBrand(e.target.value)}
            sx={{ mb: 1 }}
          />
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Brand</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Brand"
              name="brand_id"
              value={product.brand_id}
              onChange={handleInputChange}
            >
              {filteredBrands?.map((brand) => (
                <MenuItem value={brand.id} key={brand.id}>
                  {brand.brand_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ mt: 4 }}>
          {/* Product Tags */}
          <TextField
            value={tagInput}
            onChange={handleChipsInputChange(setTagInput)}
            onKeyDown={handleAddItem(tagInput, setTagInput, "product_tags")}
            helperText="Type a tag and press Enter"
            variant="outlined"
            label="Product Tags"
            placeholder="Product Tags"
            fullWidth
          />
          <Box sx={{ mt: 2 }}>
            {product.product_tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteItem(tag, "product_tags")}
                sx={{ marginRight: 1, marginBottom: 1 }}
              />
            ))}
          </Box>

          {/* Product Sizes */}
          <TextField
            value={sizeInput}
            onChange={handleChipsInputChange(setSizeInput)}
            onKeyDown={handleAddItem(sizeInput, setSizeInput, "product_size")}
            helperText="Type a size and press Enter"
            variant="outlined"
            label="Product Sizes"
            placeholder="Product Sizes"
            sx={{ mt: 4 }}
            fullWidth
          />
          <Box sx={{ mt: 2 }}>
            {product.product_size.map((size, index) => (
              <Chip
                key={index}
                label={size}
                onDelete={() => handleDeleteItem(size, "product_size")}
                sx={{ marginRight: 1, marginBottom: 1 }}
              />
            ))}
          </Box>

          {/* Product Colors */}
          <TextField
            value={colorInput}
            onChange={handleChipsInputChange(setColorInput)}
            onKeyDown={handleAddItem(
              colorInput,
              setColorInput,
              "product_color"
            )}
            helperText="Type a color and press Enter"
            variant="outlined"
            label="Product Colors"
            placeholder="Product Colors"
            sx={{ mt: 4 }}
            fullWidth
          />
          <Box sx={{ mt: 2 }}>
            {product.product_color.map((color, index) => (
              <Chip
                key={index}
                label={color}
                onDelete={() => handleDeleteItem(color, "product_color")}
                sx={{ marginRight: 1, marginBottom: 1 }}
              />
            ))}
          </Box>
        </Box>

        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Price"
            variant="outlined"
            rows={4}
            fullWidth
            size="small"
            name="selling_price"
            helperText="Format for price 20000 NOT 20,000"
            value={product.selling_price}
            onChange={handleInputChange}
          />
          <TextField
            label="Discounted Price"
            variant="outlined"
            rows={4}
            fullWidth
            size="small"
            helperText="This is the new price you wish to sell, else leave blank"
            name="discount_price"
            value={product.discount_price}
            onChange={handleInputChange}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <FormLabel component="legend">Deal Type</FormLabel>
          <RadioGroup
            value={dealTypes.find((dealType) => product[dealType] === 1) || ""}
            onChange={handleDealTypeChange}
          >
            <FormControlLabel
              value="hot_deals"
              control={<Radio />}
              label="Hot Deals"
            />
            <FormControlLabel
              value="special_offer"
              control={<Radio />}
              label="Special Offer"
            />
            <FormControlLabel
              value="special_deals"
              control={<Radio />}
              label="Special Deals"
            />
            <FormControlLabel
              value="featured"
              control={<Radio />}
              label="Featured"
            />
          </RadioGroup>
        </Box>
        <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
          <TextField
            label="Product Code"
            variant="outlined"
            rows={4}
            fullWidth
            size="small"
            disabled
            value={product.product_code}
            defaultValue={product.product_code}
          />
          <TextField
            label="Product Quantity"
            variant="outlined"
            rows={4}
            fullWidth
            size="small"
            name="product_qty"
            onChange={handleInputChange}
            value={product.product_qty}
            defaultValue={product.product_qty}
          />
        </Box>
        <input
          type="file"
          hidden
          ref={imageInput}
          onChange={handleThumbnailChange}
        />
        <UploadBox onClick={() => imageInput.current.click()}>
          {product.product_thumbnail ? (
            <img
              src={
                product.product_thumbnail instanceof File
                  ? URL.createObjectURL(product.product_thumbnail)
                  : product.product_thumbnail
                    ? "https://api.ebn.ng/public/" + product.product_thumbnail
                    : "https://default-remote-image-url.com"
              }
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
              <Typography>
                Thumbnail Image{" "}
                <span style={{ color: "#027edd", cursor: "pointer" }}>
                  browse
                </span>
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                JPG, PNG images are allowed
              </Typography>
            </Box>
          )}
        </UploadBox>
        {id && (
          <Button
            variant="contained"
            color="primary"
            onClick={updateThumbnailImage}
            disabled={updatedThumbnail === true}
            style={{ marginTop: "16px" }}
          >
            {!updatedThumbnail ? "Update Thumbnail" : "Updated thumbnail"}
          </Button>
        )}

        {/* multiimage section */}

        <Box>
          <Typography sx={{ margin: "20px 0", fontWeight: "bold" }}>
            Please upload all 4 images.
          </Typography>

          <Grid container spacing={2}>
            {addPlaceholderImages()?.map((img, index) => {
              return (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Box>
                    <input
                      type="file"
                      hidden
                      ref={(el) => (multiImageInputs.current[index] = el)}
                      onChange={(e) => handleMultiImageChange(index, e)}
                    />
                    <UploadBox
                      onClick={() => multiImageInputs.current[index].click()}
                    >
                      {img ? (
                        <img
                          src={
                            img instanceof File
                              ? URL.createObjectURL(img)
                              : img
                                ? "https://api.ebn.ng/public/" + img?.photo_name
                                : "https://default-remote-image-url.com"
                          }
                          alt={`Product ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <Box sx={{ textAlign: "center" }}>
                          <BiImageAdd
                            style={{ fontSize: "50px", color: "#027edd" }}
                          />
                          <Typography>
                            Product image {index + 1} or{" "}
                            <span
                              style={{ color: "#027edd", cursor: "pointer" }}
                            >
                              browse
                            </span>
                          </Typography>
                          <Typography sx={{ fontSize: "12px" }}>
                            JPG, PNG images are allowed
                          </Typography>
                        </Box>
                      )}
                    </UploadBox>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {id && (
            <Button
              variant="contained"
              color="primary"
              onClick={updateMultiImages}
              disabled={updatedMulti === true}
              style={{ marginTop: "16px" }}
            >
              {!updatedMulti ? "Update Images" : "Updated Images"}
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
          }}
        >
          <Button
            onClick={CreateProduct}
            disabled={success === true}
            variant="contained"
            sx={{ borderRadius: "20px" }}
          >
            {id ? "Update Product" : "Create Product"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddProduct;
