export const messages = [
  {
    id: "m1",
    text: "How are you, Lukas!",
    createdAt: "2022-10-10T12:48:00.000Z",
    user: {
      id: "u1",
      name: "Brian",
    },
  },
  {
    id: "m2",
    text: "I am good, good",
    createdAt: "2022-10-03T14:49:00.000Z",
    user: {
      id: "u2",
      name: "Lukas",
    },
  },
  {
    id: "m3",
    text: "What about you?",
    createdAt: "2022-10-03T14:49:40.000Z",
    user: {
      id: "u2",
      name: "Lukas",
    },
  },
  {
    id: "m4",
    text: "Good as well, preparing for the stream now.",
    createdAt: "2022-10-03T14:50:00.000Z",
    user: {
      id: "u1",
      name: "Brian",
    },
  },
  {
    id: "m5",
    text: "How is your uni going?",
    createdAt: "2022-10-03T14:51:00.000Z",
    user: {
      id: "u1",
      name: "Brian",
    },
  },
  {
    id: "m6",
    text: "It is a bit tough, as I have 2 specializations. How about yours? Do you enjoy it?",
    createdAt: "2022-10-03T14:49:00.000Z",
    user: {
      id: "u2",
      name: "Lukas",
    },
  },
  {
    id: "m7",
    text: "Big Data is really interesting. Cannot wait to go through all the material.",
    createdAt: "2022-10-03T14:53:00.000Z",
    user: {
      id: "u1",
      name: "Brian",
    },
  },
  {
    id: "m8",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    createdAt: "2022-10-03T14:53:00.000Z",
    user: {
      id: "u1",
      name: "Brian",
    },
  },
  {
    id: "m9",
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
    createdAt: "2022-10-03T14:53:00.000Z",
    user: {
      id: "u2",
      name: "Lukas",
    },
  },
  {
    id: "m10",
    text: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in",
    createdAt: "2022-10-03T14:53:00.000Z",
    user: {
      id: "u2",
      name: "Lukas",
    },
  },
  {
    id: "m11",
    text: "Ok.",
    createdAt: "2022-10-03T14:53:00.000Z",
    user: {
      id: "u2",
      name: "Lukas",
    },
  },
  {
    id: "m12",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    createdAt: "2022-10-03T14:53:00.000Z",
    user: {
      id: "u1",
      name: "Brian",
    },
  },
  {
    id: "m13",
    text: "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in",
    createdAt: "2022-10-03T14:53:00.000Z",
    user: {
      id: "u1",
      name: "Brian",
    },
  },
];
