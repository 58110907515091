import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import Sidebar from "./components/common/Sidebar";
import Dashboard from "./pages/Dashboard";
import Navbar from "./components/common/Navbar";
import {
  AddProduct,
  Brands,
  Customers,
  Inbox,
  Orders,
  ProductCategories,
  Products,
  ProductSales,
  Reviews,
  SalesAnalytics,
  Settings,
  SingleCustomer,
  SingleOrder,
  SingleProduct,
  Suppliers,
  Transactions,
  AddBrand,
  AddCategory,
  Auctions,
  Subcategories,
  AddSubCategory,
  Coupons,
  Sliders,
} from "./pages";
import Footer from "./components/common/Footer";
import { useUser } from "./Context/UserProvider";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuctionIt from "./pages/AuctionIT";
import RegistrationForm from "./pages/VendorRegister";

const sideBarWidth = 250;

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useUser();

  // Check if the user is logged in
  const isUserLoggedIn = React.useCallback(
    () => user && Object.keys(user).length > 0,
    [user]
  );

  // Check if the logged-in user is an admin
  const isAdminOrVendor = React.useCallback(
    () => isUserLoggedIn() && (user.role === "admin" || user.role === "vendor"),
    [isUserLoggedIn, user]
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!isAdminOrVendor()) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/vendor/register" element={<RegistrationForm />} />
        <Route path="/admin/login" element={<Login />} />
      </Routes>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar
        sideBarWidth={sideBarWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Sidebar
        sideBarWidth={sideBarWidth}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: { xs: 1, md: 2 },
          width: { xs: "100%", md: `calc(100% - ${sideBarWidth}px)` },
        }}
      >
        {/* Routes */}
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/:id" element={<SingleProduct />} />
          <Route path="/auction-product/:id" element={<AuctionIt />} />
          <Route path="/products/categories" element={<ProductCategories />} />
          <Route path="/products/categories/add" element={<AddCategory />} />
          <Route path="/products/subcategories" element={<Subcategories />} />
          <Route path="/products/subcategories/add" element={<AddSubCategory />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/customers/:id" element={<SingleCustomer />} />
          <Route path="/sales/analysis" element={<SalesAnalytics />} />
          <Route path="/sales" element={<ProductSales />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<SingleOrder />} />
          <Route path="/vendors" element={<Suppliers />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/brands/add" element={<AddBrand />} />
          <Route path="/auctions" element={<Auctions />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/sliders" element={<Sliders />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/inbox" element={<Inbox />} />
        </Routes>
        <Footer />
      </Box>
      <ToastContainer autoClose={2000} />
    </Box>
  );
}

export default App;
