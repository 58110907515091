export const productsColumns = [
  {
    accessorKey: "product_name", //access nested data with dot notation
    header: "Product",
  },
  {
    accessorKey: "product_thumbnail", //access nested data with dot notation
    header: "Image",
    size: 100,
    Cell: ({ cell }) => {
      const imageUrl = `https://api.ebn.ng/public${cell.getValue()}`; // Construct full image URL
      return (
        <div>
          <img src={imageUrl} alt="product" width={50} />
        </div>
      );
    },
  },
  {
    accessorKey: "status", //access nested data with dot notation
    header: "Status",
  },
  {
    accessorKey: "category_id", //access nested data with dot notation
    header: "Category",
  },
  {
    accessorKey: "product_qty", //access nested data with dot notation
    header: "Quantity",
  },
  {
    accessorKey: "selling_price",
    header: "Price",
    Cell: ({ cell }) => <span>₦{cell.getValue()}</span>,
  },
  {
    accessorKey: "discount_price",
    header: "Discounted Price",
    Cell: ({ cell }) => {
      const value = cell.getValue();
      return <span>₦{value !== null ? value : 0}</span>; // Check for null and show 0 if null
    },
  },
  // {
  //   accessorKey: "instock",
  //   header: "Status",
  //   //or in the component override callbacks like this
  //   Cell: ({ cell, row }) => (
  //     <div>
  //       {row.original.instock && (
  //         <span style={{ color: "#388b84", textTransform: "capitalize" }}>
  //           In Stock
  //         </span>
  //       )}
  //       {!row.original.instock && (
  //         <span style={{ color: "#fd4332", textTransform: "capitalize" }}>
  //           Out of Stock
  //         </span>
  //       )}
  //     </div>
  //   ),
  // },
];

