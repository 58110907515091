import { Button, Divider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { auth } from "../../services/api/auth";
import { toast } from "react-toastify";

const Password = () => {
  const [loading, setLoading] = useState(false);
  const [updatePassword, setUpdatePassword] = useState({
    current: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatePassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (
      !updatePassword.current ||
      !updatePassword.newPassword ||
      !updatePassword.confirmNewPassword
    ) {
      toast.info("Please fill in all fields");
      return;
    }
    // Check if new password and confirm new password match
    if (updatePassword.newPassword !== updatePassword.confirmNewPassword) {
      toast.error("New password and confirm new password do not match");
      return;
    }
    const formData = new FormData();
    formData.append("current_password", updatePassword.current);
    formData.append("new_password", updatePassword.newPassword);
    formData.append(
      "new_password_confirmation",
      updatePassword.confirmNewPassword
    );

    auth
      .changePassword("user", formData)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.data.message);
          setUpdatePassword({
            current: "",
            newPassword: "",
            confirmNewPassword: "",
          });
        }
      })
      .catch((err) => {
        console.lor(err, "na here e be");
        if (err.response.status === 422) {
          toast.error(err.response.data.message);
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <Box>
      <Typography variant="subtitle1">Password Reset</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.8, pb: 2 }}>
        Update your password here
      </Typography>
      <Divider />
      <Box sx={{ mt: 3 }}>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Enter your old password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={updatePassword.current}
            onChange={handleChange}
            defaultValue={"124bkf#4Ef"}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="New Password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={updatePassword.newPassword}
            onChange={handleChange}
            defaultValue={"124bkf#4Ef"}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Confirm password"
            variant="outlined"
            size="small"
            type="password"
            fullWidth
            value={updatePassword.confirmNewPassword}
            onChange={handleChange}
            defaultValue={"124bkf#4Ef"}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
        >
         {loading? 'Updating...' : ' Update Password'}
        </Button>
      </Box>
    </Box>
  );
};

export default Password;
