import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Rating,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../services/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useUser } from "../Context/UserProvider";

const SingleProduct = () => {
  const { id } = useParams();
  const{user} = useUser()
  const role = user?.role
  const [product, setProduct] = useState({});
  const [categoryName, setCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if(role === 'admin'){
      api.admin.products
      .getProduct(id)
      .then((res) => {
        setProduct(res.data.Product);
        setLoading(false);
      })
      .catch(console.error);
    }else{
      api.vendor.products
      .getProduct(id)
      .then((res) => {
        setProduct(res.data.Product);
        setLoading(false);
      })
      .catch(console.error);
    }
    
  }, [id,role]);

  useEffect(() => {
    if (product?.category_id) {
      setCategoryLoading(true);
      api.vendor.category
        .getCategory(product.category_id)
        .then((res) => {
          setCategory(res.data.Category.category_name);
          setCategoryLoading(false);
        })
        .catch(console.error);
    }
  }, [product]);

  const {
    product_thumbnail,
    selling_price,
    discount_price,
    product_qty,
    product_name,
    short_description,
  } = product;

  const finalPrice = discount_price > 0 ? discount_price : selling_price;

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h4">Product Details</Typography>
      <Tooltip arrow placement="right" title="View Products">
        <IconButton color="error" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height="300px" />
            ) : (
              <img
                src={
                  "https://api.ebn.ng/public" +
                  product_thumbnail
                }
                alt={product_name}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            {loading ? (
              <Skeleton variant="text" width="60%" height={40} />
            ) : (
              <Typography variant="h4">{product_name}</Typography>
            )}
            {loading ? (
              <Skeleton variant="text" width="40%" height={30} />
            ) : (
              <Typography variant="h5">
                {discount_price > 0 && (
                  <span
                    style={{
                      opacity: 0.7,
                      textDecoration: "line-through",
                      fontSize: "13px",
                      marginRight: "8px",
                    }}
                  >
                    ₦{selling_price}
                  </span>
                )}
                ₦{finalPrice}
              </Typography>
            )}
            {loading ? (
              <Skeleton variant="text" width="30%" height={25} />
            ) : (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 4, my: 2 }}
              >
                <Typography variant="subtitle2">
                  284 customer reviews
                </Typography>
                <Rating
                  value={Number((Math.random() * 5).toFixed(2))}
                  precision={0.5}
                  readOnly
                />
              </Box>
            )}
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={100} />
            ) : (
              <p
                className="text-lg lg:text-xl text-justify my-4"
                dangerouslySetInnerHTML={{ __html: short_description }}
              ></p>
            )}
            {categoryLoading ? (
              <Skeleton variant="text" width="30%" />
            ) : (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 4, my: 2 }}
              >
                <Typography variant="subtitle2">Category</Typography>
                <Chip label={categoryName} />
              </Box>
            )}
            {loading ? (
              <Skeleton variant="text" width="30%" />
            ) : (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 4, my: 2 }}
              >
                <Typography variant="subtitle2">Stock Count</Typography>
                <Chip
                  label={product_qty}
                  color={product_qty > 1 ? "success" : "error"}
                />
              </Box>
            )}
            {loading ? (
              <Skeleton variant="text" width="30%" />
            ) : (
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 4, my: 2 }}
              >
                <Typography variant="subtitle2">Availability</Typography>
                <Chip
                  label={product_qty > 1 ? "In stock" : "Out Of Stock"}
                  color={product_qty > 1 ? "success" : "error"}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SingleProduct;
