export const ordersColumns = [
  {
    accessorKey: "payment_reference", //access nested data with dot notation
    header: "Payment Reference",
  },
  {
    accessorKey: "order_number",
    header: "Order Number",
  },
  {
    accessorKey: "order_date", //normal accessorKey
    header: "Date",
  },
  {
    accessorKey: "invoice_no",
    header: "Invoice No",
  },
  {
    accessorKey: "status",
    header: "Status",
    //or in the component override callbacks like this
    Cell: ({ cell, row }) => (
      <div>
        {row.original.status === "pending" && (
          <span className={`status Pending`}>{cell.getValue()}</span>
        )}
        {row.original.status !== "pending" && row.original.status !== 'cancelled' && (
          <span className={`status Delivered`}>{cell.getValue()}</span>
        )}
        {row.original.status === "cancelled" && (
          <span className={`status Cancelled`}>{cell.getValue()}</span>
        )}
      </div>
    ),
  },
  {
    accessorKey: "total_product_amount",
    header: "Amount",
    Cell: ({ cell }) => (
      <div>
        <span>₦{cell.getValue()}</span>
      </div>
    ),
  },
];
