import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Paper,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { auth } from "../services/api/auth";
import service from "../services";
import { useUser } from "../Context/UserProvider";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

function Login() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const navigate = useNavigate();

  const { setUser } = useUser();

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = (e) => {
    setIsLogin(true);
    e.preventDefault();
    if (currentPath === "/admin/login") {
      auth
        .login("admin", { email, password })
        .then((res) => {
          setUser(res.data.user);
          // setToken
          service.setTokensLocalStorage({ access: res.data.access_token });
          service.setUserLocalStorage(res.data?.user);
          navigate("/dashboard");
        })
        .catch((err) => console.log(err));
    } else {
      auth
        .login("vendor", { email, password })
        .then((res) => {
          setUser(res.data.user);
          // setToken
          service.setTokensLocalStorage({ access: res.data.access_token });
          service.setUserLocalStorage(res.data?.user);
          navigate("/dashboard");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, width: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ gap: 2 }}
        >
          <Typography component="h1" variant="h5">
            {currentPath === "/admin/login" ? "Admin Login" : "Vendor Login"}
          </Typography>
          <form onSubmit={handleLogin} style={{ width: "100%" }}>
            <Box sx={{ mb: 2 }}>
              <TextField
                variant="outlined"
                fullWidth
                label="Email Address"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                variant="outlined"
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button
              type="submit"
              disabled={isLogin}
              fullWidth
              variant="outlined"
              sx={{ color: "green", borderColor: "gray", fontWeight: 600 }}
            >
              {isLogin ? <CircularProgress /> : "Sign In"}
            </Button>
            {currentPath !== "/admin/login" && <p>Dont have an account? <a href="/vendor/register">Register</a></p>}
          </form>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
