import { Rating } from "@mui/material";

export const reviewsClumns = [
  {
    accessorKey: "product", //access nested data with dot notation
    header: "Product",
  },
  {
    accessorKey: "username",
    header: "Username",
  },
  {
    accessorKey: "rating", //normal accessorKey
    header: "Rating",
    Cell: ({ cell, row }) => (
      <Rating
        name="half-rating"
        defaultValue={cell.getValue()}
        precision={0.5}
        readOnly
      />
    ),
  },
  {
    accessorKey: "date", //normal accessorKey
    header: "Date",
  },
  {
    accessorKey: "status",
    header: "Status",
    //or in the component override callbacks like this
    Cell: ({ cell, row }) => (
      <div>
        {row.original.status === "Pending" && (
          <span
            className="status"
            style={{ color: "#c49c1a", backgroundColor: "#c49c1a33" }}
          >
            {cell.getValue()}
          </span>
        )}
        {row.original.status === "Published" && (
          <span
            className="status"
            style={{ color: "#388b84", backgroundColor: "#388b8433" }}
          >
            {cell.getValue()}
          </span>
        )}
      </div>
    ),
  },
];

export const reviews = [
  {
    product: "Eel - Smoked",
    username: "Tuesday Byard",
    rating: 4.3,
    date: "10/05/2022",
    status: "Pending",
  },
  {
    product: "Tomato - Green",
    username: "Rhea Kynforth",
    rating: 1.4,
    date: "10/15/2022",
    status: "Pending",
  },
  {
    product: "Pastry - Apple Large",
    username: "Rodie Garland",
    rating: 4.6,
    date: "04/29/2022",
    status: "Pending",
  },
  {
    product: "Lamb - Leg, Bone In",
    username: "Layton MacShirie",
    rating: 1.9,
    date: "07/23/2022",
    status: "Published",
  },
  {
    product: "Green Scrubbie Pad H.duty",
    username: "Onfre Billitteri",
    rating: 4.6,
    date: "04/09/2022",
    status: "Published",
  },
  {
    product: "The Pop Shoppe Pinapple",
    username: "Langston Stredwick",
    rating: 4.6,
    date: "05/08/2022",
    status: "Pending",
  },
  {
    product: "Onions - Green",
    username: "Colette Versey",
    rating: 4.9,
    date: "11/04/2021",
    status: "Published",
  },
  {
    product: "Mushroom - Morels, Dry",
    username: "Ceil Klaus",
    rating: 0.7,
    date: "01/22/2022",
    status: "Published",
  },
  {
    product: "Mushroom - Morels, Dry",
    username: "Hermione Bradshaw",
    rating: 1.1,
    date: "09/19/2022",
    status: "Published",
  },
  {
    product: "Dc Hikiage Hira Huba",
    username: "Evangelia Richard",
    rating: 4.9,
    date: "11/06/2022",
    status: "Pending",
  },
  {
    product: "Coffee - Flavoured",
    username: "Dusty Bouldon",
    rating: 4.9,
    date: "11/12/2022",
    status: "Published",
  },
  {
    product: "Hog / Sausage Casing - Pork",
    username: "Jillie Joblin",
    rating: 3.5,
    date: "12/22/2022",
    status: "Pending",
  },
  {
    product: "Butcher Twine 4r",
    username: "Rawley Matiasek",
    rating: 2.0,
    date: "01/07/2022",
    status: "Published",
  },
  {
    product: "Roe - Lump Fish, Black",
    username: "Germana Booker",
    rating: 2.4,
    date: "01/07/2022",
    status: "Published",
  },
  {
    product: "Syrup - Golden, Lyles",
    username: "Kent Poulney",
    rating: 2.9,
    date: "09/04/2022",
    status: "Pending",
  },
  {
    product: "Parasol Pick Stir Stick",
    username: "Morganica Eathorne",
    rating: 3.6,
    date: "06/05/2022",
    status: "Pending",
  },
  {
    product: "Oranges - Navel, 72",
    username: "Minnie Divill",
    rating: 4.5,
    date: "11/18/2021",
    status: "Published",
  },
  {
    product: "Bread - Roll, Soft White Round",
    username: "Cordelie Eskrick",
    rating: 1.3,
    date: "09/21/2022",
    status: "Published",
  },
  {
    product: "Ham Black Forest",
    username: "Ned Ravenscraft",
    rating: 4.9,
    date: "10/29/2021",
    status: "Published",
  },
  {
    product: "Mushroom - Oyster, Fresh",
    username: "Randie McLugaish",
    rating: 2.7,
    date: "02/26/2022",
    status: "Pending",
  },
  {
    product: "Mustard - Dijon",
    username: "Leanora Likely",
    rating: 0.6,
    date: "07/15/2022",
    status: "Published",
  },
  {
    product: "Beef - Tenderlion, Center Cut",
    username: "Sonja Tacey",
    rating: 3.2,
    date: "12/10/2021",
    status: "Published",
  },
  {
    product: "Lid - Translucent, 3.5 And 6 Oz",
    username: "Ranna Ghilardi",
    rating: 1.3,
    date: "04/09/2022",
    status: "Published",
  },
  {
    product: "Wine - Lou Black Shiraz",
    username: "Liane Geraldo",
    rating: 4.2,
    date: "08/01/2022",
    status: "Pending",
  },
  {
    product: "Smoked Paprika",
    username: "Ambur Eyden",
    rating: 3.4,
    date: "03/31/2022",
    status: "Published",
  },
  {
    product: "Filo Dough",
    username: "Warde Sclater",
    rating: 0.9,
    date: "08/26/2022",
    status: "Published",
  },
  {
    product: "Chocolate - Pistoles, White",
    username: "Sofie Bossingham",
    rating: 1.4,
    date: "09/22/2022",
    status: "Published",
  },
  {
    product: "Pepper - Red Bell",
    username: "Lou Spaule",
    rating: 2.1,
    date: "01/18/2022",
    status: "Pending",
  },
  {
    product: "Pork - Belly Fresh",
    username: "Ulrich Butcher",
    rating: 3.1,
    date: "01/20/2022",
    status: "Published",
  },
  {
    product: "Wine - Zinfandel Rosenblum",
    username: "Freda Brackpool",
    rating: 3.9,
    date: "10/03/2022",
    status: "Published",
  },
  {
    product: "Cheese - Marble",
    username: "Ricoriki MacAleese",
    rating: 2.5,
    date: "05/28/2022",
    status: "Published",
  },
  {
    product: "Wine - Sauvignon Blanc",
    username: "Annamaria Vogl",
    rating: 3.1,
    date: "11/29/2022",
    status: "Published",
  },
  {
    product: "Anisette - Mcguiness",
    username: "Teador Bourdel",
    rating: 1.9,
    date: "06/27/2022",
    status: "Pending",
  },
  {
    product: "Cookie Dough - Double",
    username: "Rosalyn Doogan",
    rating: 1.3,
    date: "04/07/2022",
    status: "Pending",
  },
  {
    product: "Bread - Pumpernickel",
    username: "Galvan Yakovl",
    rating: 3.8,
    date: "10/23/2022",
    status: "Pending",
  },
  {
    product: "Chinese Foods - Plain Fried Rice",
    username: "Chen Urch",
    rating: 0.3,
    date: "11/07/2021",
    status: "Published",
  },
  {
    product: "Soup - Knorr, Classic Can. Chili",
    username: "Gaelan Arnoult",
    rating: 0.7,
    date: "06/17/2022",
    status: "Pending",
  },
  {
    product: "Cognac - Courvaisier",
    username: "Feliks Deegan",
    rating: 2.1,
    date: "06/20/2022",
    status: "Published",
  },
  {
    product: "Wine - Masi Valpolocell",
    username: "Herc Roxburch",
    rating: 3.3,
    date: "04/21/2022",
    status: "Published",
  },
  {
    product: "Chicken - Leg, Fresh",
    username: "Gizela Postin",
    rating: 3.6,
    date: "03/13/2022",
    status: "Published",
  },
  {
    product: "Seedlings - Buckwheat, Organic",
    username: "Bellina Essame",
    rating: 2.6,
    date: "08/14/2022",
    status: "Published",
  },
  {
    product: "Cheese Cloth No 60",
    username: "Bradan Costigan",
    rating: 1.8,
    date: "12/28/2021",
    status: "Published",
  },
  {
    product: "Tart - Raisin And Pecan",
    username: "Noel Bonnavant",
    rating: 0.9,
    date: "04/02/2022",
    status: "Pending",
  },
  {
    product: "Soup - Campbells Mushroom",
    username: "Joli Wray",
    rating: 3.0,
    date: "11/02/2022",
    status: "Published",
  },
  {
    product: "Potatoes - Peeled",
    username: "Agosto Dinnington",
    rating: 4.7,
    date: "11/08/2022",
    status: "Published",
  },
  {
    product: "Pastry - Chocolate Marble Tea",
    username: "Jerrine Weerdenburg",
    rating: 4.0,
    date: "11/28/2021",
    status: "Pending",
  },
  {
    product: "Sea Bass - Fillets",
    username: "Francoise Radford",
    rating: 2.8,
    date: "11/19/2022",
    status: "Pending",
  },
  {
    product: "Extract - Vanilla,artificial",
    username: "Bearnard Decayette",
    rating: 2.2,
    date: "08/30/2022",
    status: "Published",
  },
  {
    product: "Cream - 35%",
    username: "Ros Livett",
    rating: 1.4,
    date: "12/13/2021",
    status: "Pending",
  },
  {
    product: "Wine - Chablis J Moreau Et Fils",
    username: "Barry Larroway",
    rating: 3.4,
    date: "10/11/2022",
    status: "Published",
  },
  {
    product: "Juice - Happy Planet",
    username: "Adiana Cordery",
    rating: 0.4,
    date: "02/19/2022",
    status: "Published",
  },
  {
    product: "Scampi Tail",
    username: "Muffin Duffyn",
    rating: 4.2,
    date: "02/05/2022",
    status: "Published",
  },
  {
    product: "Pork Salted Bellies",
    username: "Geoffry Danilchik",
    rating: 5.0,
    date: "09/04/2022",
    status: "Published",
  },
  {
    product: "Flour Pastry Super Fine",
    username: "Mario Devo",
    rating: 0.2,
    date: "06/09/2022",
    status: "Pending",
  },
  {
    product: "Coffee Beans - Chocolate",
    username: "Westbrooke Tofts",
    rating: 2.3,
    date: "02/25/2022",
    status: "Published",
  },
  {
    product: "Beer - Moosehead",
    username: "Berty Lippingwell",
    rating: 4.6,
    date: "05/18/2022",
    status: "Pending",
  },
  {
    product: "Island Oasis - Ice Cream Mix",
    username: "Karlik Genty",
    rating: 4.3,
    date: "04/10/2022",
    status: "Pending",
  },
  {
    product: "Beef - Salted",
    username: "Sarita Padginton",
    rating: 2.1,
    date: "11/16/2021",
    status: "Pending",
  },
  {
    product: "Beef - Bones, Cut - Up",
    username: "Amaleta Naptine",
    rating: 1.7,
    date: "11/20/2022",
    status: "Pending",
  },
  {
    product: "Mushroom - Morels, Dry",
    username: "Jorey Willoughby",
    rating: 0.5,
    date: "08/27/2022",
    status: "Pending",
  },
  {
    product: "Rolled Oats",
    username: "Lawrence Flindall",
    rating: 3.4,
    date: "04/12/2022",
    status: "Pending",
  },
  {
    product: "Cake - Dulce De Leche",
    username: "Rory Kupis",
    rating: 2.1,
    date: "03/07/2022",
    status: "Pending",
  },
  {
    product: "Shrimp - Baby, Cold Water",
    username: "Reggis Lemery",
    rating: 1.4,
    date: "11/13/2021",
    status: "Pending",
  },
  {
    product: "Melon - Watermelon, Seedless",
    username: "Cori Allicock",
    rating: 3.4,
    date: "12/14/2021",
    status: "Published",
  },
  {
    product: "Nantucket Apple Juice",
    username: "Angelico Moxon",
    rating: 3.3,
    date: "11/28/2021",
    status: "Pending",
  },
  {
    product: "Sherbet - Raspberry",
    username: "Ches Tugman",
    rating: 4.7,
    date: "02/25/2022",
    status: "Pending",
  },
  {
    product: "Pasta - Penne Primavera, Single",
    username: "Inigo Osmon",
    rating: 1.3,
    date: "08/31/2022",
    status: "Pending",
  },
  {
    product: "Napkin White",
    username: "Perla Eastbrook",
    rating: 1.0,
    date: "11/11/2022",
    status: "Published",
  },
  {
    product: "Appetiser - Bought",
    username: "Daffy Kardos",
    rating: 1.2,
    date: "10/04/2022",
    status: "Pending",
  },
  {
    product: "Cookies - Englishbay Chochip",
    username: "Amabel Thornthwaite",
    rating: 4.3,
    date: "04/12/2022",
    status: "Published",
  },
  {
    product: "Bread Foccacia Whole",
    username: "Lombard Stothard",
    rating: 1.4,
    date: "11/01/2021",
    status: "Published",
  },
  {
    product: "Water - Spring Water 500ml",
    username: "Shawn Nann",
    rating: 0.6,
    date: "07/08/2022",
    status: "Published",
  },
  {
    product: "Squeeze Bottle",
    username: "Colman Dove",
    rating: 1.0,
    date: "01/28/2022",
    status: "Published",
  },
  {
    product: "Coffee Guatemala Dark",
    username: "Scarface Loach",
    rating: 0.3,
    date: "11/04/2022",
    status: "Published",
  },
  {
    product: "Chicken - Breast, 5 - 7 Oz",
    username: "Cristie Blackater",
    rating: 2.9,
    date: "03/27/2022",
    status: "Pending",
  },
  {
    product: "Shrimp - Black Tiger 13/15",
    username: "Hugo Stidson",
    rating: 4.0,
    date: "05/31/2022",
    status: "Pending",
  },
  {
    product: "Beer - Blue Light",
    username: "Fran Killgus",
    rating: 2.4,
    date: "06/13/2022",
    status: "Published",
  },
  {
    product: "Squid U5 - Thailand",
    username: "Marylin Bowgen",
    rating: 0.0,
    date: "10/23/2022",
    status: "Published",
  },
  {
    product: "Miso - Soy Bean Paste",
    username: "Valentine Strowthers",
    rating: 4.6,
    date: "08/27/2022",
    status: "Published",
  },
  {
    product: "Caviar - Salmon",
    username: "Clementia Jakeway",
    rating: 3.3,
    date: "04/10/2022",
    status: "Published",
  },
  {
    product: "Slt - Individual Portions",
    username: "Urbanus Dorset",
    rating: 1.7,
    date: "01/20/2022",
    status: "Published",
  },
  {
    product: "Wine - Acient Coast Caberne",
    username: "Victor Gibbon",
    rating: 0.2,
    date: "11/18/2021",
    status: "Pending",
  },
  {
    product: "Cut Wakame - Hanawakaba",
    username: "Annalee Marjot",
    rating: 3.9,
    date: "04/21/2022",
    status: "Published",
  },
  {
    product: "Sauce - Soya, Dark",
    username: "Beaufort Veschambes",
    rating: 2.5,
    date: "03/15/2022",
    status: "Pending",
  },
  {
    product: "Wine - Baron De Rothschild",
    username: "Ailina Standish",
    rating: 0.4,
    date: "08/21/2022",
    status: "Pending",
  },
  {
    product: "Cognac - Courvaisier",
    username: "Brenn Clue",
    rating: 4.6,
    date: "06/26/2022",
    status: "Published",
  },
  {
    product: "Quail - Whole, Boneless",
    username: "Sheridan Folonin",
    rating: 4.3,
    date: "03/02/2022",
    status: "Published",
  },
  {
    product: "Mushroom - Chanterelle, Dry",
    username: "Dredi Polfer",
    rating: 4.9,
    date: "11/22/2022",
    status: "Pending",
  },
  {
    product: "Longos - Greek Salad",
    username: "Ernestus Sorbey",
    rating: 0.7,
    date: "09/04/2022",
    status: "Pending",
  },
  {
    product: "Milkettes - 2%",
    username: "Genevra Keyzor",
    rating: 0.6,
    date: "03/18/2022",
    status: "Published",
  },
  {
    product: "Beer - Labatt Blue",
    username: "Ari Isaksen",
    rating: 2.6,
    date: "09/10/2022",
    status: "Pending",
  },
  {
    product: "Bulgar",
    username: "Butch Enion",
    rating: 3.7,
    date: "06/21/2022",
    status: "Pending",
  },
  {
    product: "Garam Marsala",
    username: "Willard McGrouther",
    rating: 0.8,
    date: "11/15/2022",
    status: "Pending",
  },
  {
    product: "Ginger - Crystalized",
    username: "Thornie Mohammed",
    rating: 1.1,
    date: "09/03/2022",
    status: "Pending",
  },
  {
    product: "Beef - Rib Roast, Capless",
    username: "Meir Trood",
    rating: 4.7,
    date: "09/23/2022",
    status: "Pending",
  },
  {
    product: "Cake - Bande Of Fruit",
    username: "Elka Feeny",
    rating: 4.6,
    date: "01/11/2022",
    status: "Pending",
  },
  {
    product: "Calypso - Lemonade",
    username: "Kareem Attenborrow",
    rating: 2.4,
    date: "12/03/2021",
    status: "Pending",
  },
  {
    product: "Pepper - Paprika, Spanish",
    username: "Fiona McCloughen",
    rating: 3.4,
    date: "12/10/2021",
    status: "Published",
  },
  {
    product: "Cheese - Goat",
    username: "Patrice Hekkert",
    rating: 2.9,
    date: "11/13/2021",
    status: "Published",
  },
  {
    product: "Icecream - Dstk Strw Chseck",
    username: "Abbe MacParlan",
    rating: 0.3,
    date: "01/22/2022",
    status: "Pending",
  },
];
