import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { ordersColumns } from "../data/orders";
import api from "../services/api";
import { useUser } from "../Context/UserProvider";

const Orders = () => {
  const { user } = useUser();
  const role = user?.role;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (role === "vendor") {
      api.vendor.orders
        .getOrderHistory()
        .then((res) => setOrders(res.data?.data))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [role]);

  const order = (row, value) => {
    console.log(row, value);
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Orders
      </Typography>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={orders}
          fields={ordersColumns}
          numberOfRows={orders.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
          showPreview
          routeLink="orders"
          confirmOrder={true}
          updateOrder={order}
        />
      )}
    </Box>
  );
};

export default Orders;
