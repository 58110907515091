import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../services/api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const AddProduct = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [allCategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [creating, setCreating] = useState(false);

  const navigate = useNavigate();

  const [subCategory, setSubCategory] = useState({
    categoryName: "",
    subCategoryName: "",
  });

  useEffect(() => {
    if (id) {
      api.admin.subcategory
        .getSubcategory(id)
        .then((res) => {
          const existingCategory = res.data?.subcategory;
          setSubCategory({
            categoryName: existingCategory.category_id,
            subCategoryName: existingCategory.subcategory_name,
          });
        })
        .catch((err) => console.error(err));
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    api.admin.category
      .getCategories()
      .then((res) => {
        setAllCategories(res.data?.categories);
      })
      .catch((res) => console.log(res))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (e) => {
    setSubCategory((prev) => ({
      ...prev,
      subCategoryName: e.target.value,
    }));
  };

  const handleCategoryChange = (e) => {
    setSubCategory((prev) => ({
      ...prev,
      categoryName: e.target.value,
    }));
  };

  const isBrandValid = () => {
    return (
      subCategory.categoryName !== "" &&
      subCategory.subCategoryName.trim() !== ""
    );
  };

  const CreateSubcategory = (e) => {
    e.preventDefault();
    setSuccess(!success);
    setCreating(true);

    const formData = new FormData();
    formData.append("category_id", subCategory.categoryName);
    formData.append("subcategory_name", subCategory.subCategoryName);

    if (!isBrandValid()) {
      setSuccess(success);
      toast.error("Kindly fill the fields");
    } else {
      if (id) {
        setSuccess(!success);
        api.admin.subcategory
          .updateSubcategory(id, formData)
          .then((res) => {
            toast.success(res.data.message);
            setSuccess(!success);
            navigate(`/products/subcategories`);
          })
          .catch((err) => console.error(err))
          .finally(() => setCreating(false));
      } else {
        api.admin.subcategory
          .createSubcategory(formData)
          .then((res) => {
            toast.success(res.data.message);
            setSuccess(!success);
            navigate(`/products/subcategories`);
            setSubCategory({});
          })
          .catch((err) => console.error(err))
          .finally(() => setCreating(false));
      }
    }
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Add Subcategory
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "800px",
          margin: "0 auto",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box sx={{ mt: 4 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Category"
              value={subCategory.categoryName}
              onChange={handleCategoryChange}
            >
              {loading ? (
                <MenuItem value={""}>Select Category here</MenuItem>
              ) : (
                allCategories?.map((category) => (
                  <MenuItem value={category.id} key={category.category_slug}>
                    {category.category_name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Subcategory Name"
            variant="outlined"
            size="small"
            fullWidth
            value={subCategory.subCategoryName}
            onChange={handleChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "30px",
          }}
        >
          <Button
            disabled={creating === true}
            onClick={CreateSubcategory}
            variant="contained"
            sx={{ borderRadius: "20px" }}
          >
            {creating ? <CircularProgress /> : "Submit"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddProduct;
