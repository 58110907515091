import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  Skeleton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaTruck } from "react-icons/fa";
import { FiCalendar, } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../components/Table";
import { orderDetailsColumns } from "../data/OrderDetails";
import api from "../services/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const SingleOrder = () => {
  const [orders, setOrders] = useState([]); // All orders
  const [order, setOrder] = useState(null); // Single order to display
  const [loading, setLoading] = useState(true); // Loading state
  const { id } = useParams(); // Get order ID from URL parameters

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all orders
    api.vendor.orders
      .getOrderHistory()
      .then((res) => {
        setOrders(res.data?.data); // Set fetched orders
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    // Find the order once orders are fetched and `id` is available
    if (orders.length > 0 && id) {
      const foundOrder = orders.find((order) => order.id === parseInt(id));
      setOrder(foundOrder || null); // Set the order, or null if not found
    }
  }, [orders, id]); // Re-run whenever orders or id changes

  if (loading) {
    // Show skeleton while loading
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Skeleton variant="text" width="40%" height={40} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="100%" height={400} />
      </Box>
    );
  }

  if (!order) {
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Typography variant="h5">Order not found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
        }}
      >
        <Tooltip arrow placement="right" title="View Products">
        <IconButton color="error" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
        <Typography variant="h5">Order details</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ my: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FiCalendar />
              {/* Display order date if available */}
            </Box>
            <Typography variant="subtitle2" sx={{ opacity: 0.7, pb: 1 }}>
              Order ID #{order.id}
            </Typography>
          </Box>
          <Box>
            <span
              style={{
                padding: "3px 10px",
                borderRadius: "5px",
                textDecoration: "none",
              }}
              className={""}
            >
              {order.status}
            </span>
          </Box>
        </Box>
        <Divider />
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {/* <Grid item xs={12} sm={4}>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box>
                <IconButton
                  sx={{
                    color: "#049bf5",
                    backgroundColor: "rgba(4,155,245, 0.2) !important",
                  }}
                >
                  <FiUser />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                  Customer
                </Typography>
                {loading ? (
                  <>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="50%" />
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                      Name: <span style={{ opacity: 0.7 }}>{order.customerName}</span>
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                      Email: <span style={{ opacity: 0.7 }}>{order.customerEmail}</span>
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                      Phone: <span style={{ opacity: 0.7 }}>{order.customerPhone}</span>
                    </Typography>
                  </>
                )}
                <Link
                  style={{
                    padding: "3px 10px",
                    color: "#049bf5",
                    backgroundColor: "rgba(4, 155, 245,0.2)",
                    borderRadius: "5px",
                    textDecoration: "none",
                  }}
                >
                  View Profile
                </Link>
              </Box>
            </Box>
          </Grid> */}

          <Grid item xs={12} sm={4}>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box>
                <IconButton
                  sx={{
                    color: "#049bf5",
                    backgroundColor: "rgba(4,155,245, 0.2) !important",
                  }}
                >
                  <FaTruck />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                  Order Info
                </Typography>
                {loading ? (
                  <>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="50%" />
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                      Payment Method: <span style={{ opacity: 0.7 }}>{order.payment_method}</span>
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                      Status: <span style={{ opacity: 0.7 }}>{order.status}</span>
                    </Typography>
                  </>
                )}
                {/* <Link
                  style={{
                    padding: "3px 10px",
                    color: "#049bf5",
                    backgroundColor: "rgba(4, 155, 245,0.2)",
                    borderRadius: "5px",
                    textDecoration: "none",
                  }}
                >
                  View all
                </Link> */}
              </Box>
            </Box>
          </Grid>

          {/* <Grid item xs={12} sm={4}>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box>
                <IconButton
                  sx={{
                    color: "#049bf5",
                    backgroundColor: "rgba(4,155,245, 0.2) !important",
                  }}
                >
                  <FiMap />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                  Delivery
                </Typography>
                {loading ? (
                  <>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="50%" />
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                      City: <span style={{ opacity: 0.7 }}>{order.deliveryCity}</span>
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2, opacity: 0.7 }}>
                      {order.deliveryAddress}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2, opacity: 0.7 }}>
                      {order.poBox}
                    </Typography>
                  </>
                )}
                <Link
                  style={{
                    padding: "3px 10px",
                    color: "#049bf5",
                    backgroundColor: "rgba(4, 155, 245,0.2)",
                    borderRadius: "5px",
                    textDecoration: "none",
                  }}
                >
                  View on Map
                </Link>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Products details
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Table
            data={order?.items}
            fields={orderDetailsColumns}
            numberOfRows={order?.items.length}
            enableTopToolBar={false}
            enableBottomToolBar={false}
            enablePagination={false}
            enableRowSelection={false}
            enableColumnFilters={false}
            enableEditing={false}
            enableColumnDragging={false}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            mt: 2,
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 3,
                width: "200px",
              }}
            >
              <Typography variant="subtitle2">Subtotal</Typography>
              <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
              ₦{order.total_product_amount}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 3,
                width: "200px",
              }}
            >
              <Typography variant="subtitle2">Tax(20%)</Typography>
              <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                ${order.tax}
              </Typography>
            </Box> */}
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 3,
                width: "200px",
              }}
            >
              <Typography variant="subtitle2">Discount(10%)</Typography>
              <Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
                ${order.discount}
              </Typography>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 3,
                width: "200px",
              }}
            >
              <Typography variant="h6">Total</Typography>
              <Typography variant="h6" sx={{ opacity: 0.8 }}>
              ₦{order.total_product_amount}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SingleOrder;
