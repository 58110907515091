import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { categoriesColumns } from "../data/categories";
import api from "../services/api";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import Danger from "../assets/danger-sign.png";
import { toast } from "react-toastify";


const ProductCategories = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true);
    api.admin.category
      .getCategories()
      .then((res) => {
        setAllCategories(res.data?.categories);
      })
      .catch((res) => console.log(res))
      .finally(() => setLoading(false));
  }, []);

  const openModal = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  const deleteItem = () => {
    setDeleting(true);
    api.admin.category
      .deleteCategory(deleteId)
      .then((res) => {
        setAllCategories((prevCategories) =>
          prevCategories.filter((category) => category.id !== deleteId)
        );
        toast.success('Category deleted successfully')
        setIsModalOpen(false);
      })
      .catch(console.error)
      .finally(() => setDeleting(false));
  };

  const editCategory = (id) => {
    navigate(`/products/categories/add?id=${id}`);
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Categories</Typography>
      </Box>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={allCategories}
          fields={categoriesColumns}
          numberOfRows={allCategories.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
          handleDelete={openModal}
          editing={true}
          handleEditing={editCategory}
        />
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={Danger} alt="Danger" style={{ width: "100px" }} />
          <Typography variant="h6">Are you sure you want to delete?</Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              disabled={deleting === true}
              onClick={() => deleteItem(deleteId)}
            >
              {deleting ? <CircularProgress /> : "Yes"}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              disabled={deleting === true}
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductCategories;
