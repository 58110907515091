import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import api from "../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AuctionIt = () => {
  const { id } = useParams();
  const [auctionDetails, setAuctionDetails] = useState({
    product_name: "",
    start_time: "",
    end_time: "",
    auction_price: 0,
    description: "",
    thumbnail: null,
  });
  const [auctioning, setAuctioning] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    api.vendor.products
      .getProduct(id)
      .then((res) => {
        setAuctionDetails((prev) => ({
          ...prev,
          product_name: res.data.Product.product_name,
        }));
      })
      .catch(console.error);
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAuctionDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setAuctionDetails((prev) => ({
      ...prev,
      thumbnail: file,
    }));
  };

  const handleSubmit = () => {
    setAuctioning(true);
    // Check if all required fields are filled
    if (
      !auctionDetails.product_name ||
      !auctionDetails.start_time ||
      !auctionDetails.end_time ||
      !auctionDetails.auction_price ||
      !auctionDetails.description
    ) {
      alert("Please fill in all required fields.");
      return; // Prevent form submission if any field is empty
    }

    // Ensure start and end times are valid
    const currentDate = new Date();
    const startTime = new Date(auctionDetails.start_time);
    const endTime = new Date(auctionDetails.end_time);

    if (startTime < currentDate) {
      toast.info("Start time cannot be in the past.");
      return;
    }

    if (endTime < currentDate) {
      toast.info("End time cannot be in the past.");
      return;
    }

    if (endTime <= startTime) {
      toast.info("End time must be after the start time.");
      return;
    }

    const formatDateTime = (date) => {
      const pad = (n) => (n < 10 ? "0" + n : n);
      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        " " +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds())
      );
    };

    const formattedStartTime = formatDateTime(startTime);
    const formattedEndTime = formatDateTime(endTime);

    // Create a new FormData object
    const formData = new FormData();

    // Append auction details to the form data
    formData.append("product_name", auctionDetails.product_name);
    formData.append("start_time", formattedStartTime);
    formData.append("end_time", formattedEndTime);
    formData.append("auction_price", auctionDetails.auction_price);
    formData.append("description", auctionDetails.description);

    // Append the thumbnail file if it exists
    if (auctionDetails.thumbnail) {
      formData.append("thumbnail", auctionDetails.thumbnail);
    }

    // POST request to the API
    api.vendor.auction
      .createAuction(formData)
      .then((response) => {
        toast.success("Auction created successfully: ", response);
        navigate("/auctions");
      })
      .catch((error) => {
        toast.error("Error creating auction: ", error);
      })
      .finally(() => setAuctioning(false));
  };

  const UploadBox = styled(Box)({
    marginTop: 30,
    height: 200,
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderColor: "divider",
  });

  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; // Handle timezone offset
    const localTime = new Date(now.getTime() - offset).toISOString();
    return localTime.slice(0, 16); // 'YYYY-MM-DDTHH:MM'
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Auction Product
      </Typography>
      <Paper
        sx={{
          boxShadow: "none !important",
          borderRadius: "12px",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "divider",
          p: "20px",
          maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        {/* Product Name */}
        <Box sx={{ my: 2 }}>
          <TextField
            label="Product Name"
            variant="outlined"
            size="small"
            fullWidth
            name="product_name"
            value={auctionDetails.product_name}
            onChange={handleInputChange}
          />
        </Box>

        {/* Auction Start Time */}
        <Box sx={{ my: 2 }}>
          <TextField
            label="Start Time"
            type="datetime-local"
            variant="outlined"
            size="small"
            fullWidth
            name="start_time"
            value={auctionDetails.start_time}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: getCurrentDateTime(),
            }}
          />
        </Box>

        {/* Auction End Time */}
        <Box sx={{ my: 2 }}>
          <TextField
            label="End Time"
            type="datetime-local"
            variant="outlined"
            size="small"
            fullWidth
            name="end_time"
            value={auctionDetails.end_time}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: auctionDetails.start_time || getCurrentDateTime(),
            }}
          />
        </Box>

        {/* Auction Price */}
        <Box sx={{ my: 2 }}>
          <TextField
            label="Auction Price"
            variant="outlined"
            size="small"
            fullWidth
            type="number"
            name="auction_price"
            value={auctionDetails.auction_price}
            onChange={handleInputChange}
          />
        </Box>

        {/* Description */}
        <Box sx={{ my: 2 }}>
          <TextField
            label="Description"
            variant="outlined"
            size="small"
            fullWidth
            multiline
            rows={4}
            name="description"
            value={auctionDetails.description}
            onChange={handleInputChange}
          />
        </Box>

        {/* Thumbnail */}
        <input
          type="file"
          hidden
          id="thumbnail-upload"
          onChange={handleThumbnailChange}
        />
        <UploadBox
          onClick={() => document.getElementById("thumbnail-upload").click()}
        >
          {auctionDetails.thumbnail ? (
            <img
              src={URL.createObjectURL(auctionDetails.thumbnail)}
              alt="Thumbnail"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <BiImageAdd style={{ fontSize: "50px", color: "#027edd" }} />
              <Typography>Upload Thumbnail</Typography>
            </Box>
          )}
        </UploadBox>

        {/* Submit Button */}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            variant="contained"
            disabled={auctioning === true}
            onClick={handleSubmit}
          >
            Start Auction {auctioning && <CircularProgress />}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AuctionIt;
