import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import Table from "../components/Table";
import { auctionsColumns } from "../data/auctions";
import { useEffect, useMemo, useState } from "react";
import api from "../services/api";
import { useUser } from "../Context/UserProvider";

const Auctions = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openAuctions, setOpenAuctions] = useState([]);
  const [closedAuctions, setClosedAuctions] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user } = useUser();
  const role = user?.role;

  // Fetch data on component mount
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        let auctionsResponse;
        if(role === 'admin'){
          auctionsResponse = await api.admin.bidding.getAllAuctions();
        }else{
          auctionsResponse = await api.vendor.auction.getAllAuctions();          
        }
        const openAuctions = auctionsResponse.data.auctions.filter(
          (auction) => auction.status === "open"
        );
        const closedAuctions = auctionsResponse.data.auctions.filter(
          (auction) => auction.status !== "open"
        );

        setOpenAuctions(openAuctions);
        setClosedAuctions(closedAuctions);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVendors();
  }, [role]);

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Filter data based on activeTab
  const filteredAuctions = useMemo(() => {
    return activeTab === 0 ? openAuctions : closedAuctions;
  }, [activeTab, openAuctions, closedAuctions]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Auctions
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ marginBottom: "20px" }}
      >
        <Tab label="Open Auctions" />
        <Tab label="Closed Auctions" />
      </Tabs>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={filteredAuctions}
          fields={auctionsColumns}
          numberOfRows={filteredAuctions?.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
        />
      )}
    </Box>
  );
};

export default Auctions;
