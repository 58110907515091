import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { couponsColumns } from "../data/coupons";
import api from "../services/api";
import Danger from "../assets/danger-sign.png";
import Modal from "../components/Modal";
import { toast } from "react-toastify";

const Coupons = () => {
  const [showForm, setShowForm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    coupon_validity: 0,
    coupon_name: "",
    coupon_discount: 0,
  });

  const fetchCoupons = () => {
    setLoading(true);
    api.admin.coupon
      .getAllCoupons()
      .then((res) => {
        setCoupons(res.data?.coupon);
      })
      .catch((res) => console.log(res))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const today = new Date().toISOString().split("T")[0];

  const showCouponForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoupon({
      ...coupon,
      [name]: value,
    });
  };

  const createCoupon = () => {
    setIsCreating(true);
    const formData = new FormData();
    formData.append("coupon_validity", coupon.coupon_validity);
    formData.append("coupon_name", coupon.coupon_name);
    formData.append("coupon_discount", coupon.coupon_discount);

    if (isUpdating) {
      api.admin.coupon
        .updateCoupon(deleteId, formData)
        .then((res) => {
          fetchCoupons();
          toast.success(res.data.message);
          setCoupon({
            coupon_validity: 0,
            coupon_name: "",
            coupon_discount: 0,
          });
          setIsUpdating(false);
          setShowForm(false);
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    } else {
      api.admin.coupon
        .createCoupon(formData)
        .then((res) => {
          fetchCoupons();
          toast.success(res.data.message);
          setCoupon({
            coupon_validity: 0,
            coupon_name: "",
            coupon_discount: 0,
          });
          setShowForm(false);
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    }
  };

  const deleteCoupon = (id) => {
    setDeleting(true);
    api.admin.coupon
      .deleteCoupon(id)
      .then((res) => {
        toast.success("Coupon deleted successfully");
        setCoupons((prevCoupons) =>
          prevCoupons.filter((coupon) => coupon.id !== id)
        );
        setIsModalOpen(false);
      })
      .catch((res) => console.log(res))
      .finally(() => setDeleting(false));
  };

  const editCoupon = (coupon) => {
    setCoupon({
      coupon_validity: coupon.coupon_validity,
      coupon_name: coupon.coupon_name,
      coupon_discount: coupon.coupon_discount,
    });
    setDeleteId(coupon.id);
    setIsUpdating(true);
    setShowForm(true);
  };

  const openModal = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Coupons</Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "green",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          onClick={showCouponForm}
        >
          Add Coupon
        </Button>
      </Box>
      {showForm && (
        <Box sx={{ py: 2, px: 3, my: 4, backgroundColor: "white" }}>
          <form>
            <Grid container spacing={3} sx={{ my: 4 }}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  component="label"
                  htmlFor="coupon_name"
                >
                  Name
                </Typography>
                <TextField
                  fullWidth
                  id="coupon_name"
                  name="coupon_name"
                  value={coupon.coupon_name}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  component="label"
                  htmlFor="coupon_discount"
                >
                  Discount
                </Typography>
                <TextField
                  fullWidth
                  id="coupon_discount"
                  name="coupon_discount"
                  value={coupon.coupon_discount}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  component="label"
                  htmlFor="coupon_validity"
                >
                  Validity
                </Typography>
                <TextField
                  fullWidth
                  type="date"
                  id="coupon_validity"
                  name="coupon_validity"
                  value={coupon.coupon_validity}
                  onChange={handleChange}
                  variant="outlined"
                  margin="normal"
                  InputProps={{ inputProps: { min: today } }}
                />
              </Grid>
            </Grid>
          </form>

          <Button
            variant="contained"
            color="primary"
            onClick={createCoupon}
            disabled={isCreating}
            sx={{ mt: 2 }}
          >
            {isCreating ? (
              <CircularProgress />
            ) : isUpdating ? (
              "Update Coupon"
            ) : (
              "Create Coupon"
            )}
          </Button>
        </Box>
      )}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={coupons}
          fields={couponsColumns}
          numberOfRows={coupons.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
          couponEdit={editCoupon}
          handleDelete={openModal}
        />
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={Danger} alt="Danger" style={{ width: "100px" }} />
          <Typography variant="h6">Are you sure you want to delete?</Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              disabled={deleting === true}
              onClick={() => deleteCoupon(deleteId)}
            >
              {deleting ? <CircularProgress /> : "Yes"}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              disabled={deleting === true}
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Coupons;
