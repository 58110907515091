export const auctionsColumns = [
  {
    accessorKey: "thumbnail", //access nested data with dot notation
    header: "Logo",
    width: 100,
    Cell: ({ cell }) => {
      const imageUrl = `https://ebn.itl.ng/public/upload/"${cell.getValue()}`; // Construct full image URL
      return (
        <div>
          <img src={imageUrl} alt="auction" width={50} />
        </div>
      );
    },
  },
  {
    accessorKey: "id", //access nested data with dot notation
    header: "Auction Id",
  },
  {
    accessorKey: "product_name", //access nested data with dot notation
    header: "Product Name",
  },
  {
    accessorKey: "auction_price",
    header: "Auction Price",
    Cell: ({ cell }) => <span>₦{cell.getValue()}</span>,
  },
  {
    accessorKey: "start_time",
    header: "Start Time",
  },
  {
    accessorKey: "end_time",
    header: "End Time",
  },
];
