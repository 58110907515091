import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Table from "../components/Table";
import { suppliersColumns } from "../data/suppliers";
import { useEffect, useMemo, useState } from "react";
import api from "../services/api";
import Modal from "../components/Modal";
import Danger from "../assets/danger-sign.png";
import { toast } from "react-toastify";

const Suppliers = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeVendors, setActiveVendors] = useState([]);
  const [inactiveVendors, setInactiveVendors] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const [actionVendor, setActionVendor] = useState(false);

  const openModal = (id) => {
    setVendorId(id);
    setIsModalOpen(true);
  };

  // Fetch data on component mount
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        // Fetch active and inactive vendors
        const activeResponse = await api.admin.vendor.getActive();
        const inactiveResponse = await api.admin.vendor.getInActive();

        setActiveVendors(activeResponse.data.data); // Assuming API returns the data in `data`
        setInactiveVendors(inactiveResponse.data.data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  // Handle Tab Change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Filter data based on activeTab

  const activateDeactivate = (row) => {
    setActionVendor(true);
    if (row.status === "active") {
      api.admin.vendor
        .deactivateVendor(row.id)
        .then((res) => {
          setIsModalOpen(false);
          toast.error("Vendor deactivated");
          setActiveVendors((prev) =>
            prev.filter((vendor) => vendor.id !== row.id)
          );
        })
        .catch((res) => console.log(res))
        .finally(() => setActionVendor(false));
    } else {
      api.admin.vendor
        .activateVendor(row.id)
        .then((res) => {
          setIsModalOpen(false);
          toast.success("Vendor activated");
          setInactiveVendors((prev) =>
            prev.filter((vendor) => vendor.id !== row.id)
          );
        })
        .catch((res) => console.log(res))
        .finally(() => setActionVendor(false));
    }
  };

  const filteredSuppliers = useMemo(() => {
    return activeTab === 0 ? activeVendors : inactiveVendors;
  }, [activeTab, activeVendors, inactiveVendors]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "14px" }}>
        Vendors
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ marginBottom: "20px" }}
      >
        <Tab label="Active Vendors" />
        <Tab label="Inactive Vendors" />
      </Tabs>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={filteredSuppliers}
          fields={suppliersColumns}
          numberOfRows={filteredSuppliers?.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
          vendor={true}
          handleVendor={openModal}
        />
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={Danger} alt="Danger" style={{ width: "100px" }} />
          <Typography variant="h6">
            Are you sure you want to{" "}
            {activeTab === 0 ? "Deactivate" : "Activate"}?
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              disabled={actionVendor === true}
              onClick={() => activateDeactivate(vendorId)}
            >
              {actionVendor ? <CircularProgress /> : "Yes"}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              disabled={actionVendor === true}
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Suppliers;
