import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { productsColumns } from "../data/products";
import api from "../services/api";
import Danger from "../assets/danger-sign.png";
import Modal from "../components/Modal";
import { toast } from "react-toastify";
import { useUser } from "../Context/UserProvider";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const { user } = useUser();
  const role = user?.role;

  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchProducts = () => {
    setLoading(true);
    if (role === "admin") {
      api.admin.products
        .getProducts()
        .then((res) => {
          setProducts(res.data?.products);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    } else {
      api.vendor.products
        .getProducts()
        .then((res) => {
          setProducts(res.data?.products);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateProduct = (id) => {
    api.admin.products
      .activateProduct(id)
      .then((res) => fetchProducts())
      .catch((res) => console.log(res));
  };

  const openModal = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  const deleteItem = () => {
    setDeleting(true);
    if (role === "admin") {
      api.admin.products
        .deleteProduct(deleteId)
        .then((res) => {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product.id !== deleteId)
          );
          toast.success("Product deleted successfully");
          setIsModalOpen(false);
        })
        .catch(console.error)
        .finally(() => setDeleting(false));
    } else {
      api.vendor.products
        .deleteProduct(deleteId)
        .then((res) => {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product.id !== deleteId)
          );
          toast.success("Product deleted successfully");
          setIsModalOpen(false);
        })
        .catch(console.error)
        .finally(() => setDeleting(false));
    }
  };

  const handleEditing = (id) => {
    navigate(`/products/add?id=${id}`);
  };

  const handleAuction = (id) => {
    navigate(`/auction-product/${id}`);
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Products</Typography>
      </Box>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Table
          data={products}
          fields={productsColumns}
          numberOfRows={products.length}
          enableTopToolBar={true}
          enableBottomToolBar={true}
          enablePagination={true}
          enableRowSelection={true}
          enableColumnFilters={true}
          enableEditing={true}
          enableColumnDragging={true}
          showPreview={true}
          routeLink="products"
          products={true}
          handleActivateProduct={activateProduct}
          handleDelete={openModal}
          auction={handleAuction}
          handleEditing={handleEditing}
        />
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={Danger} alt="Danger" style={{ width: "100px" }} />
          <Typography variant="h6">Are you sure you want to delete?</Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              disabled={deleting === true}
              onClick={() => deleteItem(deleteId)}
            >
              {deleting ? <CircularProgress /> : "Yes"}
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              disabled={deleting === true}
              onClick={() => setIsModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Products;
