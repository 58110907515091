import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      {/* Close button */}
      <IconButton
        edge="end"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* Modal content */}
      <DialogContent dividers>
        <div style={{ padding: "20px 0" }}>{children}</div>
      </DialogContent>

      {/* Optional actions section */}
      <DialogActions sx={{ justifyContent: "flex-end", padding: "16px" }}>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
