import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; // For navigation (if using React Router)
import { auth } from "../services/api/auth";
import { toast } from "react-toastify";

const RegistrationForm = () => {
  const [details, setFormData] = useState({
    shortInfo: "",
    email: "",
    state: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    address: "",
    storeName: "",
    photo: null, // Change to null since it's a file, not a string
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate(); // If you're using react-router

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "photo" && files) {
      setFormData((prevState) => ({
        ...prevState,
        photo: files[0], // Store the first file selected
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let validationErrors = {};
    if (details.password !== details.confirmPassword) {
      validationErrors.password = "Passwords do not match.";
    }
    if (!details.email.includes("@")) {
      validationErrors.email = "Please enter a valid email.";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("email", details.email);
    formData.append("name", details.storeName);
    formData.append("phone", details.phoneNumber);
    formData.append("address", details.address);
    formData.append("password", details.password);
    formData.append("password_confirmation", details.confirmPassword);
    formData.append("vendor_short_info", details.shortInfo);
    if (details.photo) {
      formData.append("photo", details.photo); // Append the photo as a file
    }

    auth
      .registerVendor(formData)
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            shortInfo: "",
            email: "",
            state: "",
            password: "",
            confirmPassword: "",
            phoneNumber: "",
            address: "",
            storeName: "",
            photo: null, // Reset the photo field
          });
          toast.success(response.data?.message, { autoClose: 1000 });
          navigate("/");
        }
      })
      .catch((error) => {
        console.log("register", error);
        toast.error(error.data);
      })
      .finally(() => setLoading(false));
  };

  const handleLoginRedirect = () => {
    navigate("/"); 
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 900, margin: "auto" }}>
      <Typography variant="h4" gutterBottom>
        Register
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Section 1: Store Information */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Store Name"
              name="storeName"
              value={details.storeName}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Short Info"
              name="shortInfo"
              value={details.shortInfo}
              onChange={handleChange}
              error={Boolean(errors.shortInfo)}
              helperText={errors.shortInfo}
            />
          </Grid>

          {/* Section 2: Contact Information */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={details.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={details.phoneNumber}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={details.address}
              onChange={handleChange}
            />
          </Grid>

          {/* Section 3: Password */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={details.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={details.confirmPassword}
              onChange={handleChange}
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword}
            />
          </Grid>

          {/* Section 4: Image Upload */}
          <Grid item xs={12}>
            <input
              accept="image/*"
              type="file"
              name="photo"
              onChange={handleChange}
              style={{ width: "100%" }}
            />
            {details.photo && (
              <Box sx={{ marginTop: 2 }}>
                {/* Display Image Preview */}
                <img
                  src={URL.createObjectURL(details.photo)}
                  alt="Selected Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    objectFit: "contain", 
                    marginTop: "8px",
                  }}
                />
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  {details.photo.name}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Register"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Login Link */}
      <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
        <Typography variant="body2" color="textSecondary">
          Already have an account?{" "}
          <Link
            component="button"
            variant="body2"
            onClick={handleLoginRedirect} // Navigate to login page
            sx={{ cursor: "pointer" }}
          >
            Login
          </Link>
        </Typography>
      </Grid>
    </Box>
  );
};

export default RegistrationForm;
